import { InputTextarea } from 'primereact/inputtextarea';

export const InputTextareaChangesChecked = ({ id, label, formik, className, setShowDialog, rows }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error">{formik.errors[id]}</small>;
	};

	return (
		<div className="p-field p-col p-flex-column">
			<label htmlFor={id} className='block'>{label}</label>
			<InputTextarea key={'inputtextarea-' + id} id={id} name={id} rows={rows ? rows : 5} type='text' value={formik.values[id]} onChange={(e) => { setShowDialog(true); formik.handleChange(e) }} autoFocus className={' ' + className} autoComplete="off" />
			{getFormErrorMessage(id)}
		</div>
	)

}

