import React, {useContext , useState, useEffect} from "react";
import { getData, updateListEntryInProvider ,updateServiceListener} from '../feathers'
import {LoggerContext} from "../Utils/logger";
import { useCurrentUserIsAdmin } from "./CurrentUserContext";

const EmployeeStatusContext = React.createContext();

const servicename = 'employee-status';
const listenernames = ['created','updated','patched'];
const providername = 'EmployeeStatusProvider';

export function useEmployeeStatus(){
    return useContext(EmployeeStatusContext);
}

export function EmployeeStatusProvider({children}){
    const [employeeStatusList, setEmployeeStatusList] = useState();
    const [listItemChanged,setListItemChanged] = useState();
    const isAdmin = useCurrentUserIsAdmin();

    useEffect(()=>{
        if(listItemChanged){
            updateListEntryInProvider(providername,listItemChanged,employeeStatusList,setEmployeeStatusList,servicename,false)
            setListItemChanged(null);
        }
    },[listItemChanged,employeeStatusList])

    useEffect(()=>{
        LoggerContext.debug('########### ' + providername + ' useEffect update');
        updateServiceListener(servicename,listenernames,(_listentry) => setListItemChanged(_listentry));    
    },[employeeStatusList])

    useEffect(()=>{
        LoggerContext.debug('########### ' + providername + ' useEffect');
        getData(servicename).then((_list)=>{
            if(isAdmin){
                LoggerContext.info(`########### ${providername} list: ${JSON.stringify(_list)}`)
            }         
            setEmployeeStatusList(_list);
        }).catch((error) => {LoggerContext.error("Error " + providername,error)});
        return () => {updateServiceListener(servicename,listenernames,null); }
    },[isAdmin])

    return (
        <EmployeeStatusContext.Provider value={employeeStatusList}>
            {children}
        </EmployeeStatusContext.Provider>
    )
}