import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import { stripSlashes } from '@feathersjs/commons';
import { UserPatchError, PatchDataError, GetDataError, FindDataError, CreateDataError } from './errorClasses'
import urlIO from './config.js'
import { reactLocalStorage } from 'reactjs-localstorage';
import { isBefore, parse, add, format, addDays, differenceInMilliseconds, isAfter, parseISO } from 'date-fns'
import logger, { LoggerContext } from './Utils/logger';

const bcrypt = require('bcryptjs');

const socket = io(urlIO);
const client = feathers();
export const timeout = 5000;


client.hooks({
	error(context) {
		if (context.error.message === 'Not authenticated') {
			console.error(`Not authenticated Error in try to reAuthenticate`);
		} else if (context.path !== 'authentication' && context.method !== 'remove') {
			console.error(`Error in '${context.path}' service method '${context.method}' message:${context.error.message}\n`, context.error.stack);
		}

	},
	before: {
		all: [(context) => {
			const { app, path, method, app: { authentication: authService } } = context
			// bypass this for the actual auth service
			logger.debug("service: " + path + " method: " + method, app);
			if (stripSlashes(authService.options.path) === path && method === 'create') {
				return context
			}
			return context;
		}]
	}
})

client.configure(feathers.socketio(socket, {
	timeout
}));

client.configure(feathers.authentication({
	storage: window.localStorage,
	handleSocket: socket
}));

export const setupSocketErrorHandling = (errorCallback, successCallback) => {
	socket.removeAllListeners();
	socket.on('connect', successCallback);
	socket.on('error', errorCallback);
	socket.on('connect_error', errorCallback);
	socket.on('connect_failed', errorCallback);
	socket.on('disconnect', errorCallback);
}

export const checkPassword = (user, oldPassword) => {
	return bcrypt.compareSync(oldPassword, user.password); // true
}

export const logout = async () => {
	reactLocalStorage.remove('refreshToken')
	reactLocalStorage.remove('expireTime');
	logger.debug("############### LOGOUT ###############")
	client.logout();
	return null;
}

export const createAdhoc = (employeeId, available) => {
	const adhocService = client.service('adhoc');
	const createdAt = new Date();
	const adhoc = { available, employeeId, createdAt };
	adhocService.create(adhoc);
}

export const createCaptureActivity = (employeeId, tagId, terminalId, captureTypeId) => {
	const captureService = client.service('capture-activity');
	const capture = { "idOnTerminal": 0, "timestamp": Date.now(), tagId, employeeId, terminalId, captureTypeId, "serverCaptureActivityId": 0 };
	captureService.create(capture);
}

export const getCaptureActivityDataTimerange = async (start, end) => {
	const service = client.service('capture-activity');
	const _start = addDays(start, -7);
	const _end = addDays(end, 7);
	return await service.find({
		query: {
			active: true,
			timestamp: { $and: [{ $gte: _start }, { $lte: _end }] },
			employeeId: { $ne: null },
			$sort: { timestamp: -1 },
			$select: ['id', 'employeeId', 'timestamp', 'captureTypeId', 'reportId', 'terminalId']
		}
	}).then(result => {
		const data = result.data ? result.data : result;
		logger.info('getCaptureActivityDataTimerange: ' + format(_start, 'yyyy-MM-dd HH:mm') + ' - ' + format(_end, 'yyyy-MM-dd HH:mm') + ' result:' + data.length)
		return data;
	});
}

export const getCaptureActivityForReport = async (id) => {
	const service = client.service('capture-activity');
	return await service.find({
		query: { reportId: id, $select: ['id', 'employeeId', 'timestamp', 'captureTypeId', 'reportId', 'terminalId'] }
	}).then(result => {
		const data = result.data ? result.data : result;
		return data;
	});
}

export const getCaptureActivityTimerange = async (captureTypes, start, end) => {
	const captureActivityDiffToCancel = 70000;
	const captureActivityEmployeeList = [];

	await getCaptureActivityDataTimerange(start, end).then(async captureActivityList => {
		return await captureActivityList.forEach(captureActivity => {
			var captureActivityEmployeeEntry = captureActivityEmployeeList.find((entry) => entry.id === captureActivity.employeeId);
			const { employeeId, captureTypeId, employee } = captureActivity;

			if (!captureActivityEmployeeEntry) {
				captureActivityEmployeeEntry = { id: employeeId, employee, key: 0, list: [], data: [], lastCaptureActivity: null, lostCaptureActivities: [] };
				captureActivityEmployeeList.push(captureActivityEmployeeEntry);
			}

			captureActivity.key = "" + ++(captureActivityEmployeeEntry.key);
			captureActivity.children = [];
			captureActivity.label = '(' + captureActivity.key + ') ' + captureActivity.captureType.name + ' - ' + format(parseISO(captureActivity.timestamp), 'dd.MM.yyyy HH:mm:ss');

			delete captureActivity.employee;
			delete captureActivity.terminal;
			const { lastCaptureActivity } = captureActivityEmployeeEntry;

			if (captureTypeId !== 2 || lastCaptureActivity === null) {
				/** capture type ist NICHT Kommen oder ist NUR ein Kommen*/
				captureActivityEmployeeEntry.list.unshift(captureActivity);
				captureActivityEmployeeEntry.lastCaptureActivity = captureActivity;
			} else {
				captureActivity.diffToLastCaptureActivity = differenceInMilliseconds(parseISO(lastCaptureActivity.timestamp), parseISO(captureActivity.timestamp));
				const lastCaptureTypeId = lastCaptureActivity.captureTypeId;
				const noDiffToLastCaptureActivity = captureActivity.diffToLastCaptureActivity < captureActivityDiffToCancel;
				const sameTerminalId = lastCaptureActivity.terminalId === captureActivity.terminalId;
				if (lastCaptureTypeId !== 2) {
					/** capture type ist NICHT Kommen*/
					if (noDiffToLastCaptureActivity && sameTerminalId) {
						lastCaptureActivity.children.push(captureActivity);
						captureActivityEmployeeEntry.lastCaptureActivity = null;
					} else {
						captureActivityEmployeeEntry.lostCaptureActivities.unshift(captureActivity);
					}
				} else {
					/** zusätzliches Kommen */

					if (captureActivity.diffToLastCaptureActivity < captureActivityDiffToCancel * 2) {
						captureActivityEmployeeEntry.list.shift();
						captureActivity.children.push(lastCaptureActivity);
					}
					captureActivityEmployeeEntry.lastCaptureActivity = captureActivity;
					captureActivityEmployeeEntry.list.unshift(captureActivity);
				}
			}
		});
	});

	const formatString = 'HH:mm:ss';
	console.log('getCaptureActivityTimerange captureActivityEmployeeList:', captureActivityEmployeeList)
	captureActivityEmployeeList.forEach(async captureActivityEmployeeEntry => {
		delete captureActivityEmployeeEntry.lastCaptureActivity;
		let lastArrive = null; let lastLeave = null;
		await captureActivityEmployeeEntry.list.forEach(captureActivity => {
			const { captureTypeId } = captureActivity
			if (captureTypeId === 2 || captureTypeId > 3) {
				if (lastArrive === null) {
					lastArrive = captureActivity;
				} else {
					const diffToLastArrive = differenceInMilliseconds(parseISO(captureActivity.timestamp), parseISO(lastArrive.timestamp));
					if (diffToLastArrive < captureActivityDiffToCancel * 2) {
						/* Es wurden zwei Kommen in kurzer Zeit gescannt und 2. Scan wird ignoriert */
						lastArrive.children.push(captureActivity);
					} else {
						/* Weiteres Kommen ohne Gehen */
						lastArrive = captureActivity;
						captureActivityEmployeeEntry.lostCaptureActivities.push(captureActivity);
					}
				}
			} else if (captureTypeId === 3) {
				if (lastArrive !== null) {
					if (isBefore(parseISO(lastArrive.timestamp), end) && isAfter(parseISO(captureActivity.timestamp), start)) {
						const durationSeconds = differenceInMilliseconds(parseISO(captureActivity.timestamp), parseISO(lastArrive.timestamp)) / 1000;
						const oneDay = (60 * 60 * 24);
						const durationDays = (durationSeconds - durationSeconds % oneDay) / oneDay
						const displayDate = new Date((durationSeconds - durationDays * oneDay - 3600) * 1000)
						const duration = (durationDays > 0 ? durationDays + 'd ' : '') + format(displayDate, formatString)
						captureActivityEmployeeEntry.data.push({ arrive: lastArrive, leave: captureActivity, duration });
					}
				}
				lastArrive = null;
				lastLeave = captureActivity;
			} else {
				/** Scan ist ein Cancel */
			}
		})
	})
	/** prüfen, ob das arrive-leave paar zum start-end Zeitraum passt  
  */
	return captureActivityEmployeeList;

}

export const getTimeEntryForEmployee = async (id, start, end) => {
	const service = client.service('time-entry');
	return await service.find({
		query: {
			employeeId: id,
			active: 1,
			$or: [
				{ start: { $and: [{ $gte: start }, { $lte: end }] } },
				{ end: { $and: [{ $gte: start }, { $lte: end }] } },
				{ start: { $lte: start }, end: { $gte: end } }
			]
		}
	}).then(result => {
		const data = result.data ? result.data : result;
		return data;
	});
}



export const getPersonsStandBy = async (id) => {
	const service = client.service('employee-organization-functions');
	const serviceStandBy = client.service('standby');
	const standby = await serviceStandBy.get(id)
	const listPersons = await service.find({ query: { organizationFunctionId: standby.organizationFunctionId } }).then(result => result.data ? result.data : result);
	const colors = [
		{ color: '#FFFFFF', backcolor: '#00203F' },
		{ color: '#FFFFFF', backcolor: '#990011' },
		{ color: '#FFFFFF', backcolor: '#2C5F2D' },
		{ color: '#000000', backcolor: '#EDFF00' },
		{ color: '#FFFFFF', backcolor: '#606060' },
		{ color: '#FFFFFF', backcolor: '#0063B2' },
		{ color: '#000000', backcolor: '#97BC62' },
		{ color: '#000000', backcolor: '#FEE715' },
		{ color: '#000000', backcolor: '#D6ED17' },
		{ color: '#000000', backcolor: '#ED2B33' },
		{ color: '#000000', backcolor: '#ADEFD1' },
		{ color: '#000000', backcolor: '#FFFFFF' },
		{ color: '#FFFFFF', backcolor: '#000000' },
	]
	var counter = 0;
	const sortedlistPersons = await listPersons.sort((a, b) => a.id - b.id);
	await sortedlistPersons.forEach((person) => {
		person.color = colors[counter].color;
		person.backcolor = colors[counter].backcolor;
		counter++;
	})
	return { ...standby, listPersons: sortedlistPersons }
}

export const getStandByEntries = async (id, start, end) => {
	const serviceStandBy = client.service('standby-service');
	return await serviceStandBy.find({
		query: {
			standbyId: id,
			$or: [
				{ start: { $and: [{ $gte: start }, { $lte: end }] } },
				{ end: { $and: [{ $gte: start }, { $lte: end }] } }
			]
		}
	}).then(result => {
		const data = result.data ? result.data : result;
		return data;
	});
}

export const updateStandByEntry = async (standby, start, end, employee) => {
	const serviceStandBy = client.service('standby-service');

	start = parse(standby.start, 'HH:mm', start)
	end = parse(standby.end, 'HH:mm', end)

	do {

		await serviceStandBy.find({ query: { start } }).then(async (list) => {
			logger.debug('updateStandByEntry: ' + format(start, 'yyyy-MM-dd HH:mm') + " found:" + list.length);
			const standbyEnd = add(start, { days: 1 })
			if (isBefore(new Date(), standbyEnd)) {
				if (list.length === 0) {
					await serviceStandBy.create({ standbyId: standby.id, employeeId: employee.id, start, end: standbyEnd });
				} else {
					const entry = list[0];
					await serviceStandBy.patch(entry.id, { ...entry, employeeId: employee.id });
				}
			}
		})

		start = add(start, { days: 1 })
	}
	while (isBefore(start, end));

}

export const getTimeEntryCategoryTypeEmployee = async (employee) => {
	const service = client.service('time-entry-category-type-employee');
	return (employee ? await service.find({ query: { employeeId: employee.id } }).then(result => result.data ? result.data : result) : []);
}

export const getAdhocEmployee = async (id) => {
	const service = client.service('adhoc');
	return await service.find({ query: { employeeId: id } }).then(result => result.data ? result.data : result);
}

export const updateTimeEntry = async (timeEntry) => {
	const service = client.service('time-entry');
	const { id, employeeId, title, start, end, active } = timeEntry
	const updateTimeEntry = {
		id, employeeId, title, start, end, active,
		timeEntryCategoryId: timeEntry.timeEntryCategory.id,
		timeEntryCategoryTypeEmployeeId: timeEntry.timeEntryCategoryType ? timeEntry.timeEntryCategoryType.id : null,
		secondsToArrive: timeEntry.timeEntryCategoryType ? timeEntry.timeEntryCategoryType.secondsToArrive : null,
	}
	if (!timeEntry.id) {
		logger.info("create TimeEntry:", updateTimeEntry);
		return await service.create(updateTimeEntry);
	} else {
		logger.info("patch TimeEntry:", updateTimeEntry);
		return await service.patch(timeEntry.id, updateTimeEntry);
	}
}

export const getAvailability = async (date, duration) => {
	const service = client.service('availability');

	const listAvailability = await service.find({
		query: {
			date, duration
		},
		paginate: false
	})
	return listAvailability;
}

export const getOpenTags = async () => {
	const service = client.service('tag');

	const listTags = await service.find({
		query: {
			$select: ['id', 'tagId', 'active'],
			employeeId: null
		},
		paginate: false
	})
	logger.debug(`getOpenTags: \n${JSON.stringify(listTags)}`)
	return listTags;
}

export async function updateListEntryInProvider(providername, _listentry, origList, setUpdatedList, servicename, fetchNew) {
	if (!_listentry.refId || _listentry.refId === 0) {
		if (!_listentry) {
			logger.error(providername + " updateListEntryInProvider entry is nothing:");
			return;
		}

		if (fetchNew && _listentry.id) {
			_listentry = await getData(servicename, _listentry.id).catch((error) => {
			});
		}
		const _origList = origList.splice(0);
		var index = _origList.findIndex(e => e.id === _listentry.id);
		if (index === -1) {
			_origList.push(_listentry)
		} else {
			_origList[index] = _listentry;
		}
		setUpdatedList(_origList);
		LoggerContext.debug(`${providername} updateListEntryInProvider index: ${index} id:${_listentry.id} listentries:${_origList.length}`);
	}
}

export function updateServiceListener(servicename, listenernames, handleListEntryChanged) {
	const service = client.service(servicename);
	listenernames.forEach((listener) => {
		service.removeListener(listener);
		if (handleListEntryChanged !== null) {
			service.on(listener, handleListEntryChanged);
		} else {
			logger.debug('CLEANUP ' + servicename);
		}
	})
}

export const getData = async (path, id) => {
	const service = client.service(path);
	logger.debug("getData " + path + " id:" + id);
	if (id) {
		return await service.get(id).catch((error) => {
			throw new GetDataError("id=" + id, error, path, id);
		});
	} else {
		return await service.find({ paginate: false }).then((result) => {
			return result.data ? result.data : result;
		}).catch((error) => {
			throw new FindDataError("", error, path)
		});
	}
}

export const createPassword = async (defaultPassword) => {
	var result = '';
	var characters = 'ABCDEFGHKLMNPQRSTUVWXYZ123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < 8; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return (defaultPassword ? 'test' : result);
}

/*export const updateData = async (path, data) => {
  const service = client.service(path);
  if(!data.id){
	return await service.create(data).catch((error) => {
	  logger.error("ERROR updateData:" + error);
	});;
  } else {
	return await service.update(data.id, data).catch((error) => {
	 throw new PatchDataError("service:" + path,error)
	});;
  }
}*/

export const patchEmployee = async (employee) => {
	const serviceUsers = client.service('users');
	const serviceEmployee = client.service('employee');
	const { id, userId, displayname, email, password, username, updatedBy } = employee;
	logger.debug(`patchEmployee: ${JSON.stringify(employee)}`)
	const roles = employee.roles ? employee.roles.map((role) => role.alias).join(", ") : '';

	var user = { displayname, email, username, roles, employeeId: id, updatedBy }
	/** Wenn keine userId vorhanden ist,muss ein neuer Anmeldeuser angelegt werden */
	if (!userId) {
		/** Anmeldeuser anlegen und ID in employee schreiben*/
		const newUser = await serviceUsers.create({ ...user, password }).catch((error) => {
			throw new CreateDataError("Employee:" + employee.lastname, error, 'users', { ...user, password })
		});
		user.id = newUser.id;
		employee.userId = newUser.id;
		logger.debug("new user created: id:" + id + " userId:" + employee.userId);
	}
	const patchuser = { ...user, id: employee.userId };
	if (id === undefined) {
		/** Employee anlegen bzw aktualisieren*/
		logger.debug("create new employee created: id:", employee);
		const newEmployee = await serviceEmployee.create(employee);
		employee.id = newEmployee.id;
		patchuser.employeeId = employee.id;
		logger.debug("new employee created: id:" + user.employeeId + " userId:" + employee.userId, employee);
		/** Anmeldeuser aktualisieren */

		await patchData('users', patchuser).catch((error) => {
			throw new UserPatchError(error, patchuser);
		});
	} else {
		/** Anmeldeuser aktualisieren */
		await patchData('users', { ...user, id: employee.userId })
	}

	/** Employee anlegen bzw aktualisieren*/
	employee = await patchData('employee', employee);
	logger.debug("patchEmployee employee:", employee);

	return employee;
}

export const patchData = async (path, data, user) => {
	const service = client.service(path);
	logger.debug("patchdata:" + path);
	/** wenn keine id vorhanden ist, muss ein neuer Datensatz angelegt werden */
	if (!data.id) {
		return await service.create(data, user).then(async (newData) => {
			logger.debug("patchdata new Data:", newData)
			/** Datensatz neu holen, weil Assoziationen beim CREATE nicht vorhanden sind */
			return await getData(path, newData.id);
		}).catch((error) => {
			throw new PatchDataError(error.message, error, path, data)
		});
	} else {
		return await service.patch(data.id, data, user).catch((error) => {
			throw new PatchDataError(error.message, error, path, data)
		});
	}
}



export default client;