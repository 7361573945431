import { useEffect } from 'react';
import { Login } from './Login/login';
import { logout } from './feathers';
import { NavigationPage } from './Navigation/NavigationPage';
import { ChangePassword } from './Login/ChangePassword';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { BusinessUnitProvider } from "./Persistence/BusinessUnitContext";
import { OrganizationFunctionProvider } from "./Persistence/OrganizationFunctionContext";
import { QualificationFunctionProvider } from "./Persistence/QualificationFunctionContext";
import { RoleProvider } from "./Persistence/RoleContext";
import { SiteProvider } from "./Persistence/SiteContext";
import { CaptureTypeProvider } from "./Persistence/CaptureTypeContext";
import { EmployeeStatusProvider } from "./Persistence/EmpoyeeStatusContext";
import { TimeEntryCategoryListProvider } from "./Persistence/TimeEntryCategoryListContext";
import { TimeEntryCategoryTypeEmployeeProvider } from "./Persistence/TimeEntryCategoryTypeEmployeeContext";
import { SelectedEmployeeProvider } from "./Persistence/SelectedEmployeeContext";
import { useCurrentUser } from './Persistence/CurrentUserContext';
import { LoginProvider } from './Persistence/LoginContext';
import { useSocketError } from './Persistence/SocketErrorContext';
import './font_awesome/css/font-awesome.min.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
import logger from './Utils/logger';


export const Application = () => {
	const currentUser = useCurrentUser();
	const socketError = useSocketError();

	useEffect(() => {
		window.onunhandledrejection = event => {
			logger.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
		};
	}, [])

	const renderPage = () => {
		logger.info("------------ APPLICATION -------------", currentUser);
		if (socketError !== null) {
			return <div className="p-d-flex p-jc-center p-ai-center" style={{ height: '100%', backgroundColor: 'rgba(255,255,255,0.3)' }}>
				<div className='p-shadow-24 p-as-center ' style={{ width: '400px' }}>
					<Message className='p-p-6' severity="warn" text={socketError} />
				</div>
			</div>
		} else if (currentUser && currentUser.forceChangePassword === "1") {
			return (
				<Dialog header={'Passwort ändern: ' + currentUser.displayname} visible={true} style={{ width: '400px' }} onHide={() => logout()}>
					<ChangePassword user={currentUser} currentUser={currentUser} hrMode={false} />
				</Dialog>
			)
		} else if (currentUser) {
			return <LoginProvider>
				<SelectedEmployeeProvider>
					<TimeEntryCategoryListProvider>
						<TimeEntryCategoryTypeEmployeeProvider>
							<SiteProvider>
								<RoleProvider>
									<BusinessUnitProvider>
										<QualificationFunctionProvider>
											<OrganizationFunctionProvider>
												<CaptureTypeProvider>
													<EmployeeStatusProvider>
														<NavigationPage />
													</EmployeeStatusProvider>
												</CaptureTypeProvider>
											</OrganizationFunctionProvider>
										</QualificationFunctionProvider>
									</BusinessUnitProvider>
								</RoleProvider>
							</SiteProvider>
						</TimeEntryCategoryTypeEmployeeProvider>
					</TimeEntryCategoryListProvider>
				</SelectedEmployeeProvider>
			</LoginProvider>

		}

		return <LoginProvider><Login /></LoginProvider>;
	}

	return renderPage()
}
