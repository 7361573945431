import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { LoggerContext } from "../Utils/logger";
import { SocketErrorProvider } from "./SocketErrorContext";

const CurrentUserContext = React.createContext();
const CurrentUserUpdateContext = React.createContext();
const CurrentUserIsAdminContext = React.createContext();

export function useCurrentUser() {
	return useContext(CurrentUserContext);
}
export function useCurrentUserUpdate() {
	return useContext(CurrentUserUpdateContext);
}
export function useCurrentUserIsAdmin() {
	return useContext(CurrentUserIsAdminContext);
}

export function CurrentUserProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(null);
	const [isAdmin, setIsAdmin] = useState(false);

	function setUser(user) {
		if (user) {
			const { id, isAdmin, displayname, roles, permissions } = user;
			setIsAdmin(user.isAdmin === 1);
			console.info(`[${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}] [C]: ########### setCurrentUser `, [{ id, isAdmin, displayname, permissions, roles }]);
		} else {
			setIsAdmin(false);
			LoggerContext.verbose("############# setCurrentUser ###############");
		}
		setCurrentUser(user);
	}


	return (
		<CurrentUserContext.Provider value={currentUser}>
			<CurrentUserIsAdminContext.Provider value={isAdmin}>
				<CurrentUserUpdateContext.Provider value={setUser}>
					<SocketErrorProvider>
						{children}
					</SocketErrorProvider>
				</CurrentUserUpdateContext.Provider>
			</CurrentUserIsAdminContext.Provider>
		</CurrentUserContext.Provider>
	)
}