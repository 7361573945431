import { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { patchData } from '../feathers';
import { SelectButton } from 'primereact/selectbutton';
import { format,parseISO } from 'date-fns'
import { useSite } from '../Persistence/SiteContext';
import { useTerminal } from '../Persistence/TerminalContext';
import './ManageSimpleListPage.scss'
import { useCurrentUser } from '../Persistence/CurrentUserContext';

export const TerminalPage = () => {
    const [id, setId] = useState();
    const [active, setActive] = useState();
    const [name, setName] = useState();
    const [location, setLocation] = useState();
    const [selectedValue, setSelectedValue] = useState();
    const [selectedSite, setSelectedSite] = useState();
    const list = useTerminal();
    const listSites = useSite();
    const currentUser = useCurrentUser();

    const op = useRef(null);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            op.current.hide();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        isMounted.current = true;
    }, [])


    const handleChangeSelectedValue = (value) => {  
        setSelectedValue(value);
        setName(value.name);
        setLocation(value.location)
        setId(value.id);
        setActive(value.active);
        setSelectedSite(value.site?value.site:listSites[0]);
    }

    const handleOnClickSaveEntry = async () => {
        await patchData('terminal', { id,active,location,siteId:selectedSite.id, name,updatedBy:currentUser.id});
        setSelectedSite(null);
        handleChangeSelectedValue({})
        op.current.hide();
    }

    const renderSelectButton = (rowData) => {
        return <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={(e) => { op.current.toggle(e); handleChangeSelectedValue(rowData) }} aria-haspopup aria-controls="tag_overlay_panel" ></Button>
    }
    const renderStatus = (rowData) => {
        return <i className={rowData.active?"pi pi-check":"pi pi-times"} style={{'fontSize': '1em'}}></i>
    }
    const renderSite = (rowData) => {
        return <div>{rowData.site?rowData.site.alias:""}</div>
    }
    const renderName = (rowData) => {
        return <div><div>{rowData.name}</div><div>{rowData.timestampAlife?format(parseISO(rowData.timestampAlife), 'dd MMM HH:mm:ss'):''}</div></div>
    }
    return (
        <>
            <div className="p-fluid p-p-4" id='manage-simple-list-page' style={{ width: '400px' }}>
                <div className="p-d-flex p-jc-center ">
                    <h3>Terminals</h3>
                </div>
                <div className="p-grid">
                   <div className="p-col">
                        <div className="p-shadow-12">
                            <DataTable value={list} dataKey="id" className="p-datatable-sm" selection={selectedValue} onSelectionChange={e => handleChangeSelectedValue(e.value)} >
                                <Column body={renderSelectButton} headerStyle={{ width: '50px' }}></Column>
                                <Column body={renderStatus} header="Status" headerStyle={{width: '50px' }}></Column>
                                <Column body={renderName} header="Name"  ></Column>
                                <Column body={renderSite} header="Werk"  headerStyle={{width: '60px' }}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            <OverlayPanel dismissable={false} ref={op} showCloseIcon id="tag_overlay_panel" style={{ backgroundColor: 'rgba(0,0,0,1)' }}>
                    <div className="p-grid" style={{ width: '400px' }} id='manage-simple-list-page-panel'>
                        <div className="p-col-fixed"  style={{ width: '200px' }}>
                            <div className="p-fluid" style={{ backgroundColor: 'rgba(0,0,0,1)' }}>
                                <div className="p-field ">
                                    <label htmlFor="status" className="p-d-block" >Status</label>
                                    <SelectButton value={active} optionLabel='name' optionValue='value' options={[{name:'aktiv',value:1}, {name:'inaktiv',value:0}]} onChange={(e) => setActive(active===0?1:0)} />
                                </div>
                                <div className="p-field ">
                                    <label htmlFor="name" className="p-d-block" >Name</label>
                                    <InputText disabled={false} autoComplete="nope" value={name} onChange={(e) => setName(e.target.value)} id="name" className=" p-d-block" />
                                </div>
                                <div className="p-field ">
                                    <label htmlFor="location" className="p-d-block" >Standort</label>
                                    <InputText disabled={false} autoComplete="nope" value={location} onChange={(e) => setLocation(e.target.value)} id="location" className=" p-d-block" />
                                </div>
                                <div className="p-field ">
                                    <Button className='p-button-success button p-m-2' onClick={handleOnClickSaveEntry} disabled={name === '' || selectedSite === null} label='Speichern' />
                                </div>
                            </div>
                        </div>
                        <div className="p-col">
                            <DataTable value={listSites} dataKey="id" headerStyle={{display:'none'}} className="p-datatable-sm" selectionMode="single" selection={selectedSite} onSelectionChange={e => setSelectedSite(e.value)} >
                                <Column selectionMode="single" headerStyle={{ width: '30px' }}></Column>
                                <Column field="name" header="Site"></Column>
                            </DataTable>
                        </div>
                    </div>
                </OverlayPanel>
        </>
    )

}