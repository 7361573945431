import React, { useEffect, useState ,useContext} from "react";
import { getData } from "../feathers";
import { LoggerContext } from "../Utils/logger";
import { useCurrentUser } from "./CurrentUserContext";

const providername = 'SelectedEmployeeProvider';

const SelectedEmployeeContext = React.createContext();
const SelectedEmployeeUpdateContext = React.createContext();

export function useSelectedEmployeeContext(){
    return useContext(SelectedEmployeeContext);
}

export function useSelectedEmployeeUpdate(){
    return useContext(SelectedEmployeeUpdateContext);
}

export const emptyEmployee = {
    active:1,siteId:'',businessUnitId:'',lastname: '', firstname: '', personalNumber: '', tags: [], user: { username:'',email: '', roles: '', password:'' }
}

export function SelectedEmployeeProvider({children}){
    const [selectedEmployee,setSelectedEmployee] = useState();
    const currentUser = useCurrentUser();

    function updateSelectedEmployee(employee){
        setSelectedEmployee(employee);
    }

    useEffect(()=>{
        
        LoggerContext.info(`####### ${providername} selectedEmployee: ${JSON.stringify(currentUser)}`);
        if(currentUser && currentUser.employee && (!selectedEmployee)){
            getData('employee',currentUser.employee.id).then((employee)=>{
                setSelectedEmployee(employee);
                LoggerContext.info(`####### ${providername} selectedEmployee: ${JSON.stringify(employee)}`);
            }).catch((error) => {LoggerContext.error("Error " + providername,error)});
        }else if(!selectedEmployee){
            setSelectedEmployee(emptyEmployee);
            LoggerContext.info(`####### ${providername} selectedEmployee: ${JSON.stringify(emptyEmployee)}`);
        }

    },[currentUser,selectedEmployee])

    return (
        <SelectedEmployeeContext.Provider value={selectedEmployee}>
            <SelectedEmployeeUpdateContext.Provider value={updateSelectedEmployee}>               
                    {children}
            </SelectedEmployeeUpdateContext.Provider>
        </SelectedEmployeeContext.Provider>

    )
}