import { CalendarEmployee } from '../Calendar/CalendarEmployee';
import { EmployeeStatus } from '../Employee/EmployeeStatus';
import { useMediaQuery } from '../Utils/useMediaQuery';


export const EmployeePage = ({ employee }) => {

	const [screenSize] = useMediaQuery();

	return (
		<div className="p-grid p-p-4" style={{ height: (screenSize[0] - 150) + 'px', backgroundColor: 'rgb(255,255,255,0)' }}>
			<div className="p-col-fixed p-p-0" style={{ width: '400px' }}>
				<EmployeeStatus employee={employee} />
			</div>
			<div className="p-col-fixed p-p-2" style={{ width: '760px' }}>
				<CalendarEmployee employee={employee} />
			</div>
			<div className="p-col" >

			</div>
		</div>
	)
}