import { FullCalendar } from 'primereact/fullcalendar';
import deLocale from '@fullcalendar/core/locales/de';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { addHours, differenceInHours, format } from 'date-fns';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { getTimeEntryForEmployee, updateTimeEntry } from '../feathers';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { useTimeEntryCategoryTypeEmployeeList } from '../Persistence/TimeEntryCategoryTypeEmployeeContext';
import logger from '../Utils/logger';
import { useMediaQuery } from '../Utils/useMediaQuery';
import './Calendar.scss';
import { CalendarTimeEntryPanel } from './CalendarTimeEntryPanel';

export const CalendarEmployee = ({ employee }) => {
	const [screenSize] = useMediaQuery();
	const [timeEntry, setTimeEntry] = useState();
	const [display, setDisplay] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [options, setOptions] = useState();
	const [showShifts, setShowShifts] = useState(false);
	const [calendar, setCalendar] = useState();

	const timeEntryCategoryEmployeeList = useTimeEntryCategoryTypeEmployeeList();
	const currentUser = useCurrentUser();

	let calendarRef = React.createRef();
	let oneClickTimer;
	let clickCnt = 0;

	useEffect(() => {
		logger.debug("----------------CalendarEmployee");
		const height = screenSize[0] > 560 ? '670px' : '760px';
		const _options = {
			height: height, contentHeight: '800px', defaultView: 'timeGridWeek',
			plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],

			dateClick: async (info) => {

				clickCnt++;
				if (clickCnt === 1) {
					oneClickTimer = setTimeout(() => clickCnt = 0, 400);
				} else if (clickCnt === 2) {
					clearTimeout(oneClickTimer);
					clickCnt = 0;
					var currentDate = new Date(info.date);
					const timeEntryCategories = timeEntryCategoryEmployeeList;

					var newTimeEntry = {
						timeEntryCategories,
						title: null,
						employeeId: employee.id,
						updatedBy: currentUser.id,
						timeEntryCategory: timeEntryCategories[0],
						timeEntryCategoryTypeEmployee: timeEntryCategories[0].timeEntryCatagoryTypeEmployee[0],
						start: currentDate,
						end: addHours(currentDate, 1),
					}
					setTimeEntry(newTimeEntry)
					setDisplay(true);
				}
			},

			eventClick: async (info) => {
				//info.el.style.backgroundColor = 'red';

				clickCnt++;
				if (clickCnt === 1) {
					oneClickTimer = setTimeout(() => clickCnt = 0, 400);
				} else if (clickCnt === 2) {
					clearTimeout(oneClickTimer);
					clickCnt = 0;
					const timeEntryCategories = timeEntryCategoryEmployeeList;
					setTimeEntry({
						timeEntryCategories,
						employeeId: employee.id,
						id: info.event.id,
						updatedBy: currentUser.id,
						title: info.event.extendedProps.timeEntryTitle,
						end: info.event.end,
						start: info.event.start,
						timeEntryCategory: timeEntryCategories.find(tec => tec.id === info.event.extendedProps.timeEntryCategory.id),
						timeEntryCategoryTypeEmployee: info.event.extendedProps.timeEntryCategoryTypeEmployee,
					})
					setDisplay(true);
				}
			},


			select: async (info) => {
				if ((differenceInHours(info.end, info.start) > 1)) {
					const timeEntryCategories = timeEntryCategoryEmployeeList;
					var newTimeEntry = {
						timeEntryCategories,
						title: null,
						employeeId: employee.id,
						updatedBy: currentUser.id,
						timeEntryCategory: timeEntryCategories[0],
						timeEntryCategoryTypeEmployee: timeEntryCategories[0].timeEntryCatagoryTypeEmployee[0],
						start: info.start,
						end: info.end,
					}
					setTimeEntry(newTimeEntry)
					setDisplay(true);
				}

			},

			customButtons: {
				specials: { text: 'S', click: () => { console.warn("Button clicked 2") } }
			},
			locales: [deLocale], weekNumbers: true,
			slotDuration: '01:00:00', slotLabelInterval: "01:00:00",
			dayHeaderFormat: { weekday: 'short', day: '2-digit', omitCommas: true },
			dayHeaderContents: (args) => {
				return args.view.type === 'timeGridWeek' ? format(args.date, 'EEEEEE dd') : '';
			},
			slotLabelFormat: [{ hour: 'numeric' }],
			allDaySlot: false, slotEventOverlap: false, selectable: true, eventDisplay: 'block',
			headerToolbar: {
				center: 'title',
				right: 'prev,next,today',
				left: 'dayGridMonth,timeGridWeek' // buttons for switching between views
			},
			views: { timeGridFourDay: { type: 'timeGrid', duration: { days: 6 }, buttonText: '6 day' } },
			editable: false, navLinks: true,
			events: function (info, successCallback, failureCallback) {
				getTimeEntries(info).then((events) => {
					logger.debug('events:', events);
					successCallback(events);
				}).catch((error) => {
					failureCallback(error);
				});

			},
		};
		setOptions(_options)
	}, [screenSize, employee, display, timeEntryCategoryEmployeeList])

	useEffect(() => {

		if (calendarRef.current && !calendar) {
			setCalendar(calendarRef.current.calendar)
		}
	}, [options])

	useEffect(() => {
		if (calendar) {
			calendar.setOption('customButtons', { specials: { text: 'S', click: () => { console.log("showShifts: " + showShifts); setShowShifts(!showShifts) } } });
		}
	}, [showShifts, options])

	const handleUpdateTimeEntry = async (timeEntry) => {
		await updateTimeEntry(timeEntry).then().catch((error) => {
			logger.error("ERROR handleUpdateTimeEntry:" + error, error);
		});
		setDisplay(false);
	}

	const getTimeEntries = async (info) => {
		if (!employee) {
			return [];
		}
		const events = await getTimeEntryForEmployee(employee.id, info.start, info.end).then((json) => {
			logger.debug("timeEntries: " + employee.id + `${JSON.stringify(json)}`, json)
			return json.map((timeEntry) => {
				const backgroundColor = timeEntry.timeEntryCategory.available ? '#05FF0590' : '#FF000090';
				const timeEntryTitle = timeEntry.title
				const title = timeEntry.timeEntryCategory.name + (timeEntryTitle ? ' - ' + timeEntryTitle : '')
				return { ...timeEntry, backgroundColor, title, timeEntryTitle }
			})
		});

		return events;
	}

	useEffect(() => {
		setRefresh(true);
	}, [])


	return (

		<> {!display ? '' :
			<CalendarTimeEntryPanel timeEntry={timeEntry} display={display} setDisplay={setDisplay} handleUpdateTimeEntry={handleUpdateTimeEntry} />
		}
			{!employee || !employee.id ? <h1>Keine Person ausgewählt.</h1> : (
				<div className="p-flex " style={{ width: '100%' }}>
					<div className="p-flex" style={{ width: '100%', display: 'none' }}>
						<Button onClick={() => { console.log("calendar: ", calendar); calendar.changeView('dayGridMonth'); }}>{'---' + showShifts}</Button>
					</div>
					<div className="fullcalendar-employee p-shadow-12">
						<div className="p-mb-1"> {!options ? '' : <FullCalendar className='fullcalendar' options={options} ref={calendarRef} />}</div>
					</div>
				</div>)}
		</>
	);
}