import React, {useContext , useState, useEffect} from "react";
import { getData } from '../feathers'
import { LoggerContext } from "../Utils/logger";


const providername = 'PermissionProvider';
const PermissionContext = React.createContext();

export function usePermissionList(){
    return useContext(PermissionContext);
}

export function PermissionProvider({children}){
    const [permissionsList, setPermissionList] = useState([]);

    useEffect(()=>{
        LoggerContext.debug('########### PermissionProvider useEffect');
        getData('permission').then((_list)=>{
            LoggerContext.info(`########### ${providername} list: ${JSON.stringify(_list)}`)
            setPermissionList(_list);
        }).catch((error) => {LoggerContext.error("Error " + providername,error)});
    },[])

    return (
        <PermissionContext.Provider value={permissionsList}>
            {children}
        </PermissionContext.Provider>
    )
}