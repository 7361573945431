import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar';
import { Fieldset } from 'primereact/fieldset';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import { ListBox } from 'primereact/listbox';
import { InputNumber } from 'primereact/inputnumber';
import { SelectButton } from 'primereact/selectbutton';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { getData, patchEmployee, patchData} from '../feathers';
import { InputTextarea } from 'primereact/inputtextarea';
import { ChangePassword } from '../Login/ChangePassword';
import { useBusinessUnit } from '../Persistence/BusinessUnitContext';
import { useQualificationFunction } from '../Persistence/QualificationFunctionContext';
import { useOrganizationFunction } from '../Persistence/OrganizationFunctionContext';
import { useRoleList } from '../Persistence/RoleContext';
import { useSite } from '../Persistence/SiteContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';

import './EmployeeForm.scss'
import logger from '../Utils/logger';
import { format } from 'date-fns';

export const EmployeeForm = ({ employee, editMode, hideOverlayPanel }) => {

    /** Felder für Anmeldeuser */
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState();
    const [password, setPassword] = useState('');

    const [id, setId] = useState();
    const [userId, setUserId] = useState();
    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [personalNumber, setPersonalNumber] = useState('');
    const [internetAddress, setInternetAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [G26Date, setG26Date] = useState();
    const [drivingLicenseDate, setDrivingLicenseDate] = useState();
    const [siteId, setSiteId] = useState();
    const [tags, setTags] = useState([]);
    const [active, setActive] = useState();
    const [businessUnitId, setBusinessUnitId] = useState();
    const [organization_functions, setOrganization_functions] = useState();
    const [qualification_functions, setQualification_functions] = useState();
    const [secordsToArriveSite, setSecordsToArriveSite] = useState();
    const [secordsToArriveHome, setSecordsToArriveHome] = useState();

    const siteList = useSite();
    const businessUnitList = useBusinessUnit();
    const organizationFunctionList = useOrganizationFunction();
    const qualificationFunctionList = useQualificationFunction();
    const rolesList = useRoleList();
    const currentUser = useCurrentUser();

    const [dialogPassword, setDialogPassword] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        if(organizationFunctionList && qualificationFunctionList && rolesList){
            setId(employee.id)
            setActive(employee.active)
            setUserId(employee.userId)
            setLastname(employee.lastname ? employee.lastname : "");
            setFirstname(employee.firstname ? employee.firstname : "");
            setPersonalNumber(employee.personalNumber ? employee.personalNumber : "");
            setInternetAddress(employee.internetAddress ? employee.internetAddress : "");
            setPhone(employee.phone ? employee.phone : "");
            setMobile(employee.mobile ? employee.mobile : "");
            setG26Date(employee.G26Date ? new Date(employee.G26Date) : "");
            setDrivingLicenseDate(employee.drivingLicenseDate ? new Date(employee.drivingLicenseDate) : "");
            setSiteId(employee.siteId ? employee.siteId : "");
            setUsername(employee.user.username)
            setPassword(employee.user.password);
            setEmail(employee.user.email);

            const _roles = employee.user.roles ? rolesList.map((r) => employee.user.roles.includes(r.alias) ? r : null).filter(e => e != null) : [];
            setRoles(_roles);
            setTags(employee.tags ? employee.tags : []);
            setBusinessUnitId(employee.businessUnitId);
            if (!employee.organization_functions) {
                setOrganization_functions([]);
            } else {
                const _orgFunct = employee.organization_functions.map((r) => organizationFunctionList.find(o => o.alias === r.alias));
                setOrganization_functions(_orgFunct);
            }

            if (!employee.qualification_functions) {
                setQualification_functions([]);
            } else {
                const _qualFunct = employee.qualification_functions.map((r) => qualificationFunctionList.find(o => o.alias === r.alias));
                setQualification_functions(_qualFunct);
            }

            var { time_entry_category_type_employees } = employee;
            time_entry_category_type_employees = (time_entry_category_type_employees ? time_entry_category_type_employees : []);
            const categorySite = time_entry_category_type_employees.find((e) => e.timeEntryCategoryTypeId === 1)
            setSecordsToArriveSite(categorySite ? categorySite.secondsToArrive : 0);
            const categoryHome = time_entry_category_type_employees.find((e) => e.timeEntryCategoryTypeId === 2)
            setSecordsToArriveHome(categoryHome ? categoryHome.secondsToArrive : 0);
        }
    }, [employee, editMode,qualificationFunctionList,organizationFunctionList,rolesList])

    const onHandleSaveEmployee = async () => {

        const time_entry_category_type_employee = [];

        if (secordsToArriveSite !== 0) {
            time_entry_category_type_employee.push({ timeEntryCategoryTypeId: 1, employeeId: employee.id, siteId, secondsToArrive: secordsToArriveSite });
        }
        if (secordsToArriveHome !== 0) {
            time_entry_category_type_employee.push({ timeEntryCategoryTypeId: 2, employeeId: employee.id, name: 'HOME', secondsToArrive: secordsToArriveHome });
        }
        const updateEmployee = {
            id: employee.id, active,
            userId, displayname: lastname + ", " + firstname, username, roles, email, password, personalNumber,
            lastname, firstname,
            siteId, businessUnitId,
            internetAddress, phone, mobile,
            G26Date:(G26Date? format(G26Date,'yyyy-MM-dd'):null), drivingLicenseDate:(drivingLicenseDate?format(drivingLicenseDate,'yyyy-MM-dd'):null),
            organization_functions, qualification_functions, time_entry_category_type_employee,
            updatedBy: currentUser.id
        };
        logger.debug('onHandleSaveEmployee', updateEmployee)
        await patchEmployee(updateEmployee).then(() => {
            if (hideOverlayPanel) { hideOverlayPanel() };
            toast.current.show({ severity: 'success', summary: 'Änderungen wurde gespeichert', life: 3000 });
        }).catch(error => {
            toast.current.show({
                severity: 'error', sticky: false, content: (
                    <div className="p-flex p-flex-column" style={{ flex: '1' }}>
                        <div className="p-text-center">
                            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                            <h4>{error.name}</h4>
                            <InputTextarea id="errormessage" value={error.message} rows={5} />
                        </div>
                    </div>
                )
            });
            logger.error('onHandleSaveEmployee', error)
        });

    }

    const panelFooterTemplate = (selectedItems) => {
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="p-py-2 p-px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    }

    const selectedTemplate = (option) => {
        if (option) {
            return (
                <div>{option.name}</div>
            );
        }
        return "auswählen";
    }

    const optionTemplate = (option) => {
        return (
            <div>{option.name}</div>
        );
    }

    const validateData = () => {
        if (!editMode) {
            return
        }
        const fields = [siteId, username, email, lastname, firstname, businessUnitId];
        if(!id){
            fields.push(password);
        }
        const fieldValues = fields.filter(e => (e === '' || e === null))
        return (fieldValues.length > 0 ?
            <Message severity="warn" text="Nicht alle benötigten Felder sind ausgefüllt" /> :
            <Button
                className={'p-button-success p-ml-2'}
                icon="pi pi-check"
                iconPos="right"
                onClick={onHandleSaveEmployee}
                label="Mitarbeiter speichern" />

        )
    }

    const clickButtonDeleteTag = async (option) => {
        confirmDialog({
            message: 'Wollen Sie den TAG <' + option.tagId + '> wirklich beim Mitarbeiter löschen?',
            header: 'Nachfrage',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel:'Ok',
            rejectLabel:'',
            accept: async () => {
                await patchData('tag', { id: option.id, employeeId: null });
                await getData('employee', id);
                const _employee = await patchData('employee', { id });
                logger.debug('clickButtonDeleteTag employee:', _employee)
                setTags(_employee.tags);
            },
        });
    }

    const renderTagOptions = (option) => {
        if (currentUser.roles.includes('tag') && editMode) {
            return <div className='p-grid'><Button icon="pi pi-trash" className="p-col-1 p-button-sm p-button-danger p-button-rounded p-button-text " onClick={() => clickButtonDeleteTag(option)} /><div className='p-col p-d-flex p-ai-center'>{option.tagId}</div></div>
        } else {
            return <div className='p-col p-d-flex p-ai-center'>{option.tagId}</div>
        }
    }

    return (
        <>
            <Dialog header={'Passwort ändern: ' + employee.user.displayname} visible={dialogPassword} style={{ width: '360px' }} onHide={() => setDialogPassword(false)}>
                <ChangePassword user={employee.user} currentUser={currentUser} hrMode={hideOverlayPanel !== undefined} hideDialog={()=>setDialogPassword(false)}/>
            </Dialog>
            <Toast ref={toast} position="top-left" />
            {(!organizationFunctionList || !qualificationFunctionList)?'':
            <Card footer={validateData()}>
                <ScrollPanel id='employeeform_maindiv' >
                    <div className="p-grid">
                        <div className="p-col employeeform_panel_basicfields" >
                            <div className="p-field p-grid">
                                <label htmlFor="status" className="p-col-fixed p-sm" >Status</label>
                                <SelectButton disabled={!editMode || !hideOverlayPanel} className="p-col p-p-0 p-m-0" value={active} optionLabel='name' optionValue='value' options={[{ name: 'aktiv', value: 1 }, { name: 'inaktiv', value: 0 }]} onChange={(e) => setActive(active === 0 ? 1 : 0)} />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="personalNumber" className="p-col-fixed p-sm" >Personalnummer</label>
                                <InputText autoComplete="nope" disabled={!editMode || !hideOverlayPanel} className="p-col p-inputtext-sm" id="personalNumber" aria-describedby="name-help" value={personalNumber} onChange={(e) => setPersonalNumber(e.target.value)} />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="firstname" className="p-col-fixed" >Vorname</label>
                                <InputText autoComplete="nope" disabled={!editMode || !hideOverlayPanel} className={'p-col p-inputtext-sm ' + (firstname === '' ? 'p-invalid' : '')} value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="lastname" className="p-col-fixed" >Nachname</label>
                                <InputText autoComplete="nope" disabled={!editMode || !hideOverlayPanel} className={'p-col p-inputtext-sm ' + (lastname === '' ? 'p-invalid' : '')} id="lastname" type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="siteId" className="p-col-fixed" >Werk</label>
                                <Dropdown disabled={!editMode || !hideOverlayPanel} className={'p-col p-inputtext-sm ' + (siteId === '' ? 'p-invalid' : '')} optionLabel="name" optionValue="id" options={siteList} name="siteId" value={siteId} id="facoryId" onChange={(e) => setSiteId(e.target.value)} />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="businessUnitId" className="p-col-fixed">Unternehmensber.</label>
                                <Dropdown disabled={!editMode || !hideOverlayPanel} className={'p-col p-inputtext-sm ' + (businessUnitId === '' ? 'p-invalid' : '')} optionLabel="name" optionValue="id" options={businessUnitList} name="businessUnitId" value={businessUnitId} id="businessUnitId" onChange={(e) => setBusinessUnitId(e.target.value)} />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="phone" className="p-col-fixed" >Telefon</label>
                                <InputText autoComplete="nope" disabled={!editMode} className="p-col p-inputtext-sm" id="phone" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="mobile" className="p-col-fixed" >Mobil</label>
                                <InputText autoComplete="nope" disabled={!editMode} className="p-col p-inputtext-sm" id="mobile" type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="G26Date" className="p-col-fixed" >G26-Datum</label>
                                <Calendar disabled={!editMode || !currentUser.roles.includes('G26') || !hideOverlayPanel} id="G26Date" dateFormat="dd.mm.yy" value={G26Date} onChange={(e) => setG26Date(e.target.value)} showIcon={editMode && currentUser.roles.includes('G26') && hideOverlayPanel !=null} />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="drivingLicenseDate" className="p-col-fixed" >Führerschein Datum</label>
                                <Calendar disabled={!editMode || !currentUser.roles.includes('driving-license') || !hideOverlayPanel} id="drivingLicenseDate" dateFormat="dd.mm.yy" value={drivingLicenseDate} onChange={(e) => setDrivingLicenseDate(e.target.value)} showIcon={editMode && currentUser.roles.includes('driving-license') && hideOverlayPanel!=null} />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="secordsToArriveSite" className="p-col-fixed" >Zeit von Werk</label>
                                <InputNumber autoComplete="off" disabled={!editMode || !hideOverlayPanel} className="p-col p-inputtext-sm" id="secordsToArriveSite" value={secordsToArriveSite} onValueChange={(e) => setSecordsToArriveSite(e.value)} suffix=" Sekunden" />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="secordsToArriveHome" className="p-col-fixed" >Zeit von Home</label>
                                <InputNumber autoComplete="off" disabled={!editMode || !hideOverlayPanel} className="p-col p-inputtext-sm" id="secordsToArriveHome" value={secordsToArriveHome} onValueChange={(e) => setSecordsToArriveHome(e.value)} suffix=" Sekunden" />
                            </div>
                            <div className="p-field p-grid">
                                <label htmlFor="tags" className="p-col-fixed" >Tags</label>
                                <ListBox id='employee-taglist' className="p-col p-inputtext-sm" disabled={!editMode || !hideOverlayPanel} itemTemplate={renderTagOptions} options={tags} optionLabel="tagId" />
                            </div>
                        </div>

                        <div className="p-col-fixed employeeform_panel_userlists">
                            <Fieldset id='fieldset_user' legend="Anmeldedaten">
                                <div className="p-field p-grid">
                                    <label className="p-col-fixed" htmlFor="username">Name</label>
                                    <InputText autoComplete="nope" disabled={!editMode || !hideOverlayPanel} value={username} id="username" className={'p-col ' + (editMode && username === '' ? 'p-invalid' : '')} onChange={(e) => setUsername(e.target.value)} />
                                </div>
                                <div className="p-field p-grid" >
                                    <label className="p-col-fixed" htmlFor="mail">E-Mail</label>
                                    <InputText autoComplete="nope" disabled={!editMode} value={email} id="mail" className={'p-col ' + (editMode && email === '' ? 'p-invalid' : '')} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                {!id?<div className="p-field p-grid">
                                    <label className="p-col-fixed" htmlFor="password">Passwort</label>
                                    <InputText autoComplete="nope" disabled={!editMode || !hideOverlayPanel} value={password} id="password" className={'p-col ' + (password === '' ? 'p-invalid' : '')} onChange={(e) => setPassword(e.target.value)} />
                                </div>:''}
                                <div className="p-field p-grid" style={!id || !editMode ? { display: 'none' } : {}}>
                                    <label className="p-col-fixed" htmlFor="button_password">Passwort</label>
                                    <Button label='Passwort setzen' id="button_password" className='p-col ' onClick={(e) => setDialogPassword(true)} />
                                </div>
                                <div className="p-field p-grid">
                                    <label className="p-col-fixed p-as-start" htmlFor="roles" >Rollen</label>
                                    <MultiSelect
                                        id='multiselect_roles'
                                        value={roles}
                                        options={rolesList}
                                        label='test'
                                        maxSelectedLabels={5}
                                        showSelectAll={true}
                                        onChange={(e) => { setRoles(e.target.value) }}
                                        panelClassName="multiselect-employeeform"
                                        className="p-col" disabled={!editMode || !hideOverlayPanel}
                                        itemTemplate={optionTemplate}
                                        selectedItemTemplate={selectedTemplate}
                                        panelFooterTemplate={panelFooterTemplate(roles)} />
                                </div>
                            </Fieldset>

                            <Fieldset id='fieldset_orgfunctions' legend="Org-Funktionen">
                                <div className="p-field p-col p-align-start">
                                    <MultiSelect
                                        id='multiselect_orgfunctions'
                                        value={organization_functions}
                                        options={organizationFunctionList}
                                        maxSelectedLabels={5}
                                        showToggleAll={false}
                                        onChange={(e) => setOrganization_functions(e.target.value)}
                                        panelClassName="multiselect-employeeform" disabled={!editMode || !hideOverlayPanel}
                                        itemTemplate={optionTemplate}
                                        selectedItemTemplate={selectedTemplate}
                                        panelFooterTemplate={panelFooterTemplate(organization_functions)} />
                                </div>
                            </Fieldset>

                            <Fieldset id='fieldset_qualfunctions' legend="Qualifikationen">
                                <div className="p-col">
                                    <MultiSelect
                                        id='multiselect_qualfunctions'
                                        value={qualification_functions}
                                        options={qualificationFunctionList}
                                        maxSelectedLabels={5}
                                        showSelectAll={false}
                                        onChange={(e) => setQualification_functions(e.target.value)}
                                        panelClassName="multiselect-employeeform" disabled={!editMode || !hideOverlayPanel}
                                        itemTemplate={optionTemplate}
                                        selectedItemTemplate={selectedTemplate}
                                        panelFooterTemplate={panelFooterTemplate(qualification_functions)} />
                                </div>
                            </Fieldset>
                        </div>

                    </div>

                </ScrollPanel>
            </Card>
            }
        </>
    );
}