import React, {useContext , useState, useEffect} from "react";
import { getData } from '../feathers'
import {LoggerContext} from "../Utils/logger";

const providername = 'CaptureTypeProvider';

const CaptureTypeContext = React.createContext();

export function useCaptureTypeList(){
    return useContext(CaptureTypeContext);
}

export function CaptureTypeProvider({children}){
    const [captureTypeList, setCaptureTypeList] = useState([]);

    useEffect(()=>{
        getData('capture-type').then((_list)=>{
            LoggerContext.info(`########### ${providername} list: ${JSON.stringify(_list)}`)
            setCaptureTypeList(_list);
        }).catch((error) => {LoggerContext.error("Error " + providername,error)});
    },[])

    return (
        <CaptureTypeContext.Provider value={captureTypeList}>
            {children}
        </CaptureTypeContext.Provider>
    )
}