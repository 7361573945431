import { useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OperationReportDialog } from './OperationReportDialog';
import { Button } from 'primereact/button';
import './OperationReport.scss';
import { addHours, parseISO } from 'date-fns';
import { useReport } from '../Persistence/ReportContext';
import format from 'date-fns/format';

const emptyReport ={
    id:null,
    subject:null,
    comment:null,
    start:addHours(new Date(),-8),
    end:new Date(),
    type:2,
    capture_activities:[]
}

export const OperationReport = () => {

    const [selectedReport, setSelectedReport] = useState(null);
    const items = useReport()

    const handleSelectReportButton = (report)=>{
        console.log("handleSelectReportButton:",report)

        setSelectedReport(report) 
    }

    const renderSelectButton = (rowData) => {
        return <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => {handleSelectReportButton({...rowData,start:parseISO(rowData.start),end:parseISO(rowData.end)}) }} aria-haspopup aria-controls="operationreport_overlay_panel" ></Button>
    }

    return (
        <div className="p-d-flex p-jc-center p-shadow-12 p-m-4 p-p-2 p-p-md-2 " style={{ backgroundColor: 'rgba(255,255,255,0)' }}>
            <DataTable
                id='operationreport' className='operationreport p-datatable-sm'
                value={items}
                selection={selectedReport}
                dataKey="id"
                header={<Button type="button" icon="pi pi-plus" label='Neuer Report' onClick={(e) => setSelectedReport({...emptyReport,start:addHours(new Date(),-8), end:new Date()})} aria-haspopup aria-controls="operationreport_overlay_panel" />}
                scrollable        
                >
                <Column body={renderSelectButton} headerStyle={{ width: '50px' }}></Column>
                <Column header="Start" headerStyle={{ width: '150px' }} body={(rowData) => {return (format(parseISO(rowData.start),'dd.MM.yyyy HH:mm'))} }></Column>
                <Column header="Ende"  headerStyle={{ width: '150px' }} body={(rowData) => {return (format(parseISO(rowData.end),'dd.MM.yyyy HH:mm'))} }></Column>
                <Column header="Thema" field='subject'></Column>
                <Column header="Kommentar" field='comment'></Column>
            </DataTable>
            <OperationReportDialog selectedReport={selectedReport} setSelectedReport={setSelectedReport}/>
        </div>
    )
}