import React, {useContext , useState, useEffect} from "react";
import { getTimeEntryCategoryTypeEmployee } from '../feathers'
import {LoggerContext} from "../Utils/logger";
import { useSelectedEmployeeContext } from "./SelectedEmployeeContext";
import { useTimeEntryCategoryList } from "./TimeEntryCategoryListContext";

const providername = 'TimeEntryCategoryTypeEmployeeProvider';

const TimeEntryCategoryTypeEmployeeContext = React.createContext();

export function useTimeEntryCategoryTypeEmployeeList(){
    return useContext(TimeEntryCategoryTypeEmployeeContext);
}

export function TimeEntryCategoryTypeEmployeeProvider({children}){
    const [timeEntryCategoryTypeEmployeeList, setTimeEntryCategoryTypeEmployeeList] = useState([]);
    const timeEntryCategoryList = useTimeEntryCategoryList();
    const selectedEmployee = useSelectedEmployeeContext();

    useEffect(()=>{
        if(selectedEmployee && timeEntryCategoryList){
            LoggerContext.info(`######## ${providername} selectedEmployee: ${selectedEmployee?selectedEmployee.id:undefined}`);
            getTimeEntryCategoryTypeEmployee(selectedEmployee).then((_timeEntryCategoryTypeEmployeeList)=>{
                var categoryTypeFactory = [];
                var categoryTypeHome = [];
                /** Beide Arrays categoryTypeFactory und  categoryTypeHome mit Daten des Employee befüllen*/
                _timeEntryCategoryTypeEmployeeList.forEach((categoryType) => {categoryType.timeEntryCategoryTypeId === 1?categoryTypeFactory.push(categoryType):categoryTypeHome.push(categoryType)}); 

                /** Liste der timeEntryCatergory durchlaufen und mit den möglichen timeEntryCategoryType des selectedEmployee ergänzen */
                const timeEntryCategoryEmployeeList = timeEntryCategoryList.map((timeEntryCategory) =>{
                    if(timeEntryCategory.timeEntryCategoryTypeId===1){
                        return timeEntryCategory = {...timeEntryCategory,timeEntryCatagoryTypeEmployee:categoryTypeFactory}
                    }else if(timeEntryCategory.timeEntryCategoryTypeId===2){
                        return timeEntryCategory = {...timeEntryCategory,timeEntryCatagoryTypeEmployee:categoryTypeHome}
                    }else{
                        return timeEntryCategory = {...timeEntryCategory,timeEntryCatagoryTypeEmployee:[]}
                    }
                });
                setTimeEntryCategoryTypeEmployeeList(timeEntryCategoryEmployeeList);
                LoggerContext.info(`######## ${providername} \n${JSON.stringify(timeEntryCategoryEmployeeList)}`);
            }).catch((error) => {LoggerContext.error("Error " + providername,error)});
        }
    },[selectedEmployee,timeEntryCategoryList])

    return (
        <TimeEntryCategoryTypeEmployeeContext.Provider value={timeEntryCategoryTypeEmployeeList}>
            {children}
        </TimeEntryCategoryTypeEmployeeContext.Provider>
    )
}