import { InputText } from "primereact/inputtext"

export const InputChangesChecked = ({ id, type, label, formik, className, setShowDialog, disabled }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error">{formik.errors[id]}</small>;
	};

	return (
		<div className="p-field p-col p-d-flex p-flex-column">
			<label htmlFor={id} className=''>{label}</label>
			<InputText key={'input-' + id} id={id} name={id} type={type} disabled={disabled} value={formik.values[id]} onChange={(e) => { setShowDialog(true); formik.handleChange(e) }} autoFocus className={'  ' + className} autoComplete="off" />
			{getFormErrorMessage(id)}
		</div>
	)

}

