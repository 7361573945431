import { addDays, addMinutes, addSeconds, differenceInMinutes, endOfDay, format, getDate, getHours, getMinutes, isAfter, isBefore, isEqual, setHours, setMinutes, startOfDay } from 'date-fns';
import { addLocale } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { SelectButton } from 'primereact/selectbutton';
import { TabPanel, TabView } from 'primereact/tabview';
import { useCallback, useEffect, useRef, useState } from 'react';
import { renderMinutesAsString } from '../Components/TimePickerChecked';
import client, { getTimeEntryForEmployee, updateTimeEntry } from '../feathers';
import logger from '../Utils/logger';
import './CalendarTimeEntryPanel.scss';

addLocale('de', {
	firstDayOfWeek: 1,
	dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
	dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
	dayNamesMin: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
	monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'Auguat', 'September', 'Oktober', 'November', 'Dezember'],
	monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
	today: 'Tag',
	clear: 'Löschen'
});

const combineDateAndTime = (date, time) => {
	let dateTime = setHours(date, getHours(time));
	return setMinutes(dateTime, getMinutes(time));
}


export const CalendarTimeEntryPanel = ({ display, setDisplay, timeEntry, handleUpdateTimeEntry }) => {

	const calStart = useRef(null);
	const calStartTime = useRef(null);
	const calEnd = useRef(null);
	const calEndTime = useRef(null);
	const buttonClose = useRef(null);
	const [listAvailable, setListAvailable] = useState([]);
	const [listNotAvailable, setListNotAvailable] = useState([]);
	const [labeltext, setLabeltext] = useState(null);
	const [templateMode, setTemplateMode] = useState(0);
	const [conflictDates, setConflictDates] = useState(null);
	const [timeEntryTemplates, setTimeEntryTemplates] = useState();
	const [selectedTimeEntryTemplate, setSelectedTimeEntryTemplate] = useState(null);
	const [title, setTitle] = useState(timeEntry.title === null ? '' : timeEntry.title);
	const [startDate, setStartDate] = useState(timeEntry.start);
	const [startTime, setStartTime] = useState(timeEntry.start);
	const [endDate, setEndDate] = useState(timeEntry.end);
	const [endTime, setEndTime] = useState(timeEntry.end);
	const [timeEntryCategory, setTimeEntryCategory] = useState(timeEntry.timeEntryCategory);
	const [timeEntryCategoryType, setTimeEntryCategoryType] = useState(timeEntry.timeEntryCategoryTypeEmployee);

	useEffect(() => {
		const timeEntryCategoryList = (!timeEntry ? [] : timeEntry.timeEntryCategories);
		const listAvailable = [];
		const listNotAvailable = [];

		timeEntryCategoryList.forEach(category => {
			if (category.available) {
				listAvailable.push(category);
			} else {
				listNotAvailable.push(category);
			}
		});
		setListAvailable(listAvailable);
		setListNotAvailable(listNotAvailable)
	}, [])

	const getTimeEntryConflicts = async (tempStart, tempEnd) => {
		const conflicts = await getTimeEntryForEmployee(timeEntry.employeeId, tempStart, tempEnd).catch((error) => { setLabeltext('FEHLER:' + error.message) });
		return conflicts ? conflicts : []
	}

	useEffect(() => {
		getUserTemplates();
		const start = combineDateAndTime(startDate, startTime);
		const end = combineDateAndTime(endDate, endTime);
		if (isBefore(end, new Date())) {
			setLabeltext('Ende liegen in der Vergangenheit!');
		} else if (isBefore(end, start)) {
			setLabeltext('Ende vor Start!');
		} else if (isEqual(end, start) || differenceInMinutes(end, start) < 30) {
			setLabeltext('Zeitdauer ist kleiner 30 Minuten!');
		} else {
			const tempStart = addSeconds(start, 1);
			const tempEnd = addSeconds(end, -1);

			getTimeEntryConflicts(tempStart, tempEnd).then((conflicts) => {
				var conflict = false;
				conflicts.forEach(entry => {
					if ("" + entry.id !== timeEntry.id) {
						conflict = true;
					}
				});
				setLabeltext(conflict ? 'Überlappender Eintrag gefunden!' : null);
			});
		}
	}, [startDate, endDate, startTime, endTime])

	const getUserTemplates = useCallback(async () => {
		const service = client.service('time-entry-templates');
		const query = { employeeId: timeEntry.employeeId }
		const start = combineDateAndTime(startDate, startTime);
		const end = combineDateAndTime(endDate, endTime);
		const startCheck = addMinutes(start, -1);
		const endCheck = addMinutes(end, 1);
		const timeEntryCategoryList = (!timeEntry ? [] : timeEntry.timeEntryCategories);
		return service.find({ query }).then(async result => {
			for (const template of result) {
				if (template.timeEntryTemplateItems) {
					for (const item of template.timeEntryTemplateItems) {
						template.timeEntryTemplateItemsId = item.id;
						template.timeEntryCategoryId = item.timeEntryCategoryId;
						template.timeEntryCategory = timeEntryCategoryList.find(entry => entry.id === template.timeEntryCategoryId);
						template.timeRange = [parseInt(item.start), parseInt(item.end)];
						template.weekdays = item.weekdays;
						template.itemActive = item.active;
						template.itemVersion = item.version;
					}
					let startDay = startOfDay(start);
					const endDay = endOfDay(addMinutes(end, -1));
					template.possibleDates = [];
					template.conflictDates = [];
					while (isBefore(startDay, endDay)) {
						const templateStart = addMinutes(startDay, template.timeRange[0]);
						const templateEnd = addMinutes(startDay, template.timeRange[1]);
						if (isAfter(templateStart, startCheck) && isBefore(templateStart, endCheck)) {
							if (isAfter(templateEnd, startCheck) && isBefore(templateEnd, endCheck)) {
								await getTimeEntryConflicts(templateStart, templateEnd).then((conflicts) => {
									if (conflicts.length === 0) {
										template.possibleDates.push(templateStart);
									} else {
										template.conflictDates.push(templateStart);
									}
								});
							}
						}
						startDay = addDays(startDay, 1)
					}
					template.disabled = template.possibleDates.length === 0;
					template.background = template.disabled ? 'rgba(255,100,100,0.7)' : (template.conflictDates.length === 0 ? 'rgba(0,200,0,0.5)' : 'rgba(230,150,50,0.5)');
					template.color = (template.disabled ? 'silver' : 'white');
				}
			}
			setTimeEntryTemplates(result);
		});
	}, [startDate, endDate, endTime, startTime])

	const handleChangeTimeEntryCategory = (e) => {
		setTimeEntryCategory(e.value);
		if (!timeEntryCategoryType || timeEntryCategoryType.timeEntryCategoryTypeId !== e.value.timeEntryCategoryTypeId) {
			setTimeEntryCategoryType(e.value.timeEntryCatagoryTypeEmployee[0]);
		}
	}

	const renderTimeEntryCategoryType = () => {
		if (!timeEntryCategory || !timeEntryCategory.timeEntryCatagoryTypeEmployee) { return '' }
		return timeEntryCategory.timeEntryCatagoryTypeEmployee.map((categoryType) => {
			return (
				<div className="p-field-radiobutton">
					<RadioButton key={categoryType.id} inputId={'' + categoryType.id} name="categoryType" value={categoryType} onChange={(e) => setTimeEntryCategoryType(e.value)} checked={timeEntryCategoryType && timeEntryCategoryType.id === categoryType.id} />
					<label htmlFor={categoryType.id}>{(!categoryType.site ? categoryType.name : 'Werk: ' + categoryType.site.alias) + ' -> Ankunft: ' + (categoryType.secondsToArrive / 60).toFixed(2) + ' min'}</label>
				</div>
			)
		});
	}

	const cancelTimeEntry = () => {
		setDisplay(false);
	}

	const saveTimeEntry = () => {
		const start = combineDateAndTime(startDate, startTime)
		const end = combineDateAndTime(endDate, endTime)
		const updatedtimeEntry = { id: timeEntry.id, active: true, employeeId: timeEntry.employeeId, title, start, end, timeEntryCategory: timeEntryCategory, timeEntryCategoryType: timeEntryCategoryType }
		logger.debug(`save Time entry ${JSON.stringify(updatedtimeEntry)} `);
		handleUpdateTimeEntry(updatedtimeEntry);
	}

	const patchTimeEntriesWithTemplate = async (template) => {
		const employeeId = timeEntry.employeeId;
		const title = template.name;
		const timeEntryCategory = template.timeEntryCategory;
		const timeEntryCategoryType = timeEntryCategory.timeEntryCategoryType;
		const templateStart = template.timeRange[0];
		const templateEnd = template.timeRange[1];

		setSelectedTimeEntryTemplate(template);
		for (const possibleDate of template.possibleDates) {
			const start = addMinutes(startOfDay(possibleDate), templateStart);
			const end = addMinutes(startOfDay(possibleDate), templateEnd);

			const updatedtimeEntry = { id: null, active: true, employeeId, title, start, end, timeEntryCategory, timeEntryCategoryType }
			console.log('---- add timeentry' + getDate(possibleDate), updatedtimeEntry)
			await updateTimeEntry(updatedtimeEntry).catch((error) => {
				logger.error("ERROR patchTimeEntriesWithTemplate:" + error.message, error);
			});
		}
		if (template.conflictDates.length) {
			setConflictDates(template.conflictDates)
		} else {
			setDisplay(false);
		}
	}

	const deleteTimeEntry = () => {
		const start = combineDateAndTime(startDate, startTime)
		const end = combineDateAndTime(endDate, endTime)
		const updatedtimeEntry = { id: timeEntry.id, active: false, employeeId: timeEntry.employeeId, title, start, end, timeEntryCategory: timeEntryCategory, timeEntryCategoryType: timeEntryCategoryType }
		logger.debug(`delete Time entry ${JSON.stringify(updatedtimeEntry)}`);
		handleUpdateTimeEntry(updatedtimeEntry);
	}

	const header = (
		<div className="p-grid p-px-2">
			<div className="p-col-fixed" style={{ width: '150px' }}>
				<label htmlFor="Titel" className="p-col-fixed">Zeiteintrag</label>
			</div>
		</div>
	);

	const footer = (
		templateMode || conflictDates ? <div className="p-grid ">
			<div className="p-col"></div><div className="p-col"></div><div className="p-col"></div>
			<Button key={"button-cancel"} id="button-cancel" className="p-col p-d-inline p-m-2" label="Close" ref={buttonClose} icon="pi pi-times" onClick={cancelTimeEntry} />
		</div> : <div className="p-grid">
			<div className="p-col" style={{ color: 'white', wordWrap: 'break-word' }}>{labeltext ? labeltext : ''}</div>
			<Button key={"button-save"} id="button-save" disabled={labeltext != null} className={`p-m-2 p-d-inline p-button-${labeltext ? 'secondary' : 'success'}`} label="Save" icon="pi pi-check" onClick={saveTimeEntry} />
			<Button key={"button-delete"} id="button-delete" className={timeEntry.id && labeltext === null ? 'p-d-inline p-m-2 p-button-danger' : 'p-d-none'} label="Delete" icon="pi pi-trash" onClick={() => deleteTimeEntry()} />
			<Button key={"button-cancel"} id="button-cancel" className="p-d-inline p-m-2" label="Cancel" ref={buttonClose} icon="pi pi-times" onClick={cancelTimeEntry} />
		</div>
	);

	return (
		<Dialog className='calendarTimeEntryPanel' id='calendarTimeEntryPanel' footer={footer} header={header} visible={display} onHide={() => setDisplay(false)} closable={true} style={{ borderColor: 'silver' }}>
			<div className={!conflictDates ? 'p-d-none' : 'p-d-flex p-flex-column'} style={{ height: '420px' }}>
				<div >Selektierter Zeitraum:</div>
				<div style={{ fontWeight: 'bold' }}>{format(combineDateAndTime(startDate, startTime), 'dd.MM.yyyy HH:mm')} - {format(combineDateAndTime(endDate, endTime), 'dd.MM.yyyy HH:mm')}</div>
				<div className='p-mt-2'>Ausgewählte Vorlage: {!selectedTimeEntryTemplate ? '' : selectedTimeEntryTemplate.name + " "}</div>
				<div className='p-mt-2'>An folgenden Tagen wurde {<span style={{ color: 'red', fontWeight: 'bold' }}>KEIN</span>} Zeiteintrag erzeugt:</div>
				{!conflictDates ? '' : conflictDates.map((conflict) => {
					return <div style={{ color: 'red', fontWeight: 'bold' }}>{format(conflict, 'dd.MM.yyyy')}</div>
				})}
			</div>
			<div className={conflictDates ? 'p-d-none' : 'p-d-flex p-flex-column'}>
				<div className="p-grid p-mx-auto p-py-3">
					<div className={conflictDates ? 'p-d-none' : 'p-col'}>
						<SelectButton value={templateMode} unselectable={false} optionLabel='label' optionValue='value' options={[{ label: 'individueller Eintrag', value: 0 }, { label: 'Vorlagen', value: 1 }]} onChange={(e) => { if (e.value !== null) { setTemplateMode(e.value) } }} />
					</div>
				</div>
				<div className='p-mx-auto'>
					<div className="p-grid">
						<label htmlFor="start" className="p-col">Start</label>
						<label htmlFor="end" className="p-col" >Ende</label>
					</div>
					<div className="p-grid">
						<div className="p-col">
							<Calendar id='calendarTimeEntryPanelStart' style={{ width: '100px', marginRight: '5px' }} tabIndex={2} ref={calStart} locale="de" value={startDate} dateFormat="dd.mm.yy" onChange={(e) => setStartDate(e.value)} />
							<Calendar id='calendarTimeEntryPanelStartTime' style={{ width: '80px' }} timeOnly tabIndex={3} ref={calStartTime} stepMinute={15} hourFormat='24' dateFormat="dd.mm.yy" locale="de" value={startTime} onChange={(e) => setStartTime(e.value)} />
						</div>
						<div className="p-col">
							<Calendar id='calendarTimeEntryPanelEnd' style={{ width: '100px', marginRight: '5px' }} tabIndex={2} ref={calEnd} locale="de" value={endDate} dateFormat="dd.mm.yy" onChange={(e) => setEndDate(e.value)} />
							<Calendar id='calendarTimeEntryPanelEndTime' style={{ width: '80px' }} timeOnly tabIndex={3} ref={calEndTime} stepMinute={15} hourFormat='24' dateFormat="dd.mm.yy" locale="de" value={endTime} onChange={(e) => setEndTime(e.value)} />
						</div>
					</div>
				</div>
				<TabView activeIndex={templateMode} onTabChange={(e) => setTemplateMode(e.index)} className='p-mt-4'>
					<TabPanel headerClassName='p-d-none' >
						<div className="p-grid p-my-2 p-mx-2">
							<label htmlFor="title" className="p-col-fixed" style={{ width: '50px' }}>Titel</label>
							<div className="p-col">
								<InputText style={{ width: '100%' }} autoFocus tabIndex={1} className="timeEntryTitel" placeholder="Beschreibung" id="calendarTimeEntryPanel_timeEntryTitel" value={title} onChange={(e) => setTitle(e.target.value)} />
							</div>
						</div>
						<div className='p-grid p-p-2'>
							<div className="p-col">
								<Fieldset id='fieldset_available' legend="verfügbar" >
									{listAvailable.map((category) => {
										return <div key={category.id} className="p-field-radiobutton">
											<RadioButton inputId={'' + category.id} name="category" value={category} onChange={handleChangeTimeEntryCategory} checked={timeEntryCategory.id === category.id} />
											<label style={category.available ? { color: '#9fdaa8' } : { color: '#f19ea6' }} htmlFor={category.id}>{category.name + ' (' + category.timeEntryCategoryType.name + ')'}</label>
										</div>
									})}
								</Fieldset>
							</div>
							<div className="p-col">
								<Fieldset id='fieldset_notavailable' legend="nicht verfügbar" >
									{listNotAvailable.map((category) => {
										return (<div key={category.id} className="p-field-radiobutton">
											<RadioButton inputId={'' + category.id} name="category" value={category} onChange={handleChangeTimeEntryCategory} checked={timeEntryCategory.id === category.id} />
											<label style={category.available ? { color: '#9fdaa8' } : { color: '#f19ea6' }} htmlFor={category.id}>{category.name}</label>
										</div>)
									})}
								</Fieldset>
							</div>
						</div>
						{renderTimeEntryCategoryType()}
					</TabPanel>
					<TabPanel headerClassName='p-d-none'>
						<div className="p-d-flex p-my-5 " style={{ width: '100%' }}>
							<div className='p-grid p-mx-auto' style={{ width: '100%', height: '280px', overflow: 'auto' }}>
								{timeEntryTemplates ?
									timeEntryTemplates.map((template) => {
										const deactivate = template.possibleDates.length === 0;
										const hasConflict = template.conflictDates.length !== 0;
										const start = renderMinutesAsString(template.timeEntryTemplateItems[0].start);
										const end = renderMinutesAsString(template.timeEntryTemplateItems[0].end);
										const border = '3px solid ' + (template.timeEntryCategory.available ? 'rgb(159, 218, 168,' + (deactivate ? '0.3)' : '1)') : 'rgb(241, 158, 166,' + (deactivate ? '0.3)' : '1)'));
										const divStyle = { backgroundColor: (deactivate ? 'rgba(100,100,100,0.3)' : 'none'), height: '80px', width: '200px', border, borderRadius: '5px' }
										const conflictStyle = { color: (deactivate ? 'rgba(255,255,255,0.3)' : 'white'), width: '150px', fontSize: '11pt', maxHeight: '60px', overflow: 'hidden' }
										const icon = (deactivate ? 'pi pi-times' : hasConflict ? 'pi pi-exclamation-triangle' : 'pi pi-check')
										return <div className='p-grid p-mt-2 p-mx-auto p-p-1 p-as-start' style={divStyle} onClick={() => { if (!deactivate) { patchTimeEntriesWithTemplate(template) } }}>
											<div className='p-col p-d-flex p-ai-center p-p-2'><icon className={icon} style={{ 'fontSize': '14pt', color: (deactivate ? 'rgba(255,0,0,0.3)' : (hasConflict ? 'rgba(255,255,20,0.5)' : 'rgba(0,255,0,0.5)')) }} /></div>
											<div className='p-col-fixed p-my-auto p-p-0' style={conflictStyle} >
												<div className='p-mb-1'>{start + ' - ' + end + ' (' + template.conflictDates.length + ')'}</div>
												<div style={{ overflow: 'hidden', maxheight: '40px' }}>{template.name}</div>
											</div>
										</div>
									}) : ''}
							</div>

						</div>
					</TabPanel>

				</TabView>
			</div>

		</Dialog >
	)

}