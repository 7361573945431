import { useState, useRef } from 'react';
import {  patchData , createPassword} from '../feathers';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';
import { ChangePassword } from '../Login/ChangePassword';
import { useUserList } from '../Persistence/UserContext';
import { useRoleList } from '../Persistence/RoleContext';
import { usePermissionList } from '../Persistence/PermissionContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext'
import './UsersPage.scss';
import logger from '../Utils/logger';

export const UsersPage = () => {

    const [selectedValue, setSelectedValue] = useState()

    const [user, setUser] = useState();
    const [id, setId] = useState();
    const [displayname, setDisplayname] = useState();
    const [username, setUsername] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [permissions, setPermissions] = useState('');
    const [roles, setRoles] = useState('');
    const [forceChangePassword, setForceChangePassword] = useState(false)

    const [dialog, setDialog] = useState(false);
    const [dialogPassword, setDialogPassword] = useState(false);
    const toast = useRef(null);
 
    const permissionsList = usePermissionList();
    const rolesList = useRoleList();
    const currentUser = useCurrentUser();
    const users = useUserList();

    const emptyValue = { username: '', email: '', forceChangePassword:'1', displayname:'',permissions:'',roles:'', password: 'init' }

    const handleChangeSelectedValue = async (v) => {
        const value = v ? { ...v } :  { ...emptyValue, password: await createPassword() };
        setUser(value);
        setSelectedValue(value);
        setId(value.id);
        setUsername(value.username);
        setEmail(value.email);
        setPermissions(value.permissions?value.permissions:'');
        setRoles(value.roles?value.roles:'');
        setPassword(value.password);
        setDisplayname(value.displayname);
    }

    const validateData = () => {
        const fields = [ username, email];
        if(!id){
            fields.push(password)
        }
        const fieldValues = fields.filter(e => (e === '' || e === null))
        return (fieldValues.length > 0 ?
            <Message severity="warn" text="Nicht alle benötigten Felder sind ausgefüllt" /> :
            <Button
                className={'p-button-success p-ml-2'}
                icon="pi pi-check"
                iconPos="right"
                onClick={handleSaveEntry}
                label={id?"Anmeldebenutzer speichern":"neuen Anmeldebenutzer anlegen"} />
            
        )
    }

    const handleSaveEntry = async (e) => {
        try{
            if (id) {
                await patchData('users', { id,username,email, permissions,roles,displayname, updatedBy:currentUser.id});
            } else {
                await patchData('users', { id,password,forceChangePassword,username,email, permissions,roles,displayname, updatedBy:currentUser.id});
            }
            setDialog(false);
        } catch(error){
            toast.current.show({
                severity: 'error', sticky: false, content: (
                    <div className="p-flex p-flex-column" style={{ flex: '1' }}>
                        <div className="p-text-center">
                            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                            <h4>{error.name}</h4>
                            <InputTextarea id="errormessage" value={error.message} rows={5} />
                        </div>
                    </div>
                )
            });
            logger.error('handleSaveEntry', error)
        }
    }

    const renderHeader = () => {
        return <div><Button className='p-ml-5' onClick={(e) => {  setDialog(true); handleChangeSelectedValue() }} aria-haspopup aria-controls="users_overlay_panel">Neuer Anmeldeuser</Button></div>
    }
    const renderSelectButton = (rowData) => {
        return <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={(e) => { setDialog(true); handleChangeSelectedValue(rowData) }} aria-haspopup aria-controls="users_overlay_panel" ></Button>
    }
    const renderEmployeePicture = (rowData) => {
        return rowData.employeeId?<i className="pi pi-user"/>:''
    }

    const onRoleChange = (e) => {
        const values = roles.split(", ").filter(entry => entry !=='');
        if (e.checked) {
            values.push(e.value.alias);
        } else{
            const pos = values.indexOf(e.value.alias)
            values.splice(pos,1)
        }
        setRoles(values.join(', '));
    }

    const onPermissionChange = async (e) => {
        const values = permissions.split(", ").filter(entry => entry !=='');
        if (e.checked) {
            values.push(e.value.alias);
        } else{
            const pos = values.indexOf(e.value.alias)
            values.splice(pos,1)
        }
        setPermissions(values.join(', '));
    }

    return (

        <div className="userspage p-grid p-m-4">
            <Toast ref={toast} position="top-left" />
            <Dialog header={'Passwort ändern: ' + displayname } visible={dialogPassword} style={{ width: '360px' }} onHide={() => setDialogPassword(false)}>
                <ChangePassword user={user} currentUser={currentUser} hrMode={true} hideDialog={()=>setDialogPassword(false)}/>
            </Dialog>
            <div className="p-col">
                <DataTable header={renderHeader()} sortField="username" sortOrder={1} scrollable scrollHeight="500px" value={users} dataKey="id" className="userspage-datatable" selection={selectedValue} onSelectionChange={(e) => { handleChangeSelectedValue(e) }}>
                    <Column body={renderSelectButton} headerClassName='column-select' className='column-select'></Column>
                    <Column body={renderEmployeePicture} headerClassName='column-select' className='column-select'></Column>
                    <Column field="username" filter filterPlaceholder="Filter nach Name" header="Name" bodyClassName='column-username' headerClassName='column-username' ></Column>
                    <Column field="email" header="E-Mail" headerClassName='column-email'  bodyClassName='column-email'  ></Column>
                    <Column field="permissions"  header="Berechtigungen" bodyClassName='column-permissions' headerClassName='column-permissions' ></Column>
                    <Column field="roles"  header="Rollen" bodyClassName='column-roles' headerClassName='column-roles' ></Column>
                </DataTable>
            </div>
            <Dialog className='userspage_dialog' footer={validateData()} header={'Benutzer ändern: ' + displayname } visible={dialog}  onHide={() => setDialog(false)}>
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="p-fluid ">
                            <div className="p-field">
                                <label className="p-col  p-p-0 p-m-1" htmlFor="displayyname">Anzeigename</label>
                                <InputText autoComplete="nope" value={displayname} id="displayyname" className={'p-col '} onChange={(e) => setDisplayname(e.target.value)} />
                            </div>                            
                            <div className="p-field">
                                <label className="p-col  p-p-0  p-m-1" htmlFor="username">Benutzername</label>
                                <InputText autoComplete="nope" value={username} id="username" className={'p-col ' + (username === '' ? 'p-invalid' : '')} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                            <div className="p-field" >
                                <label className="p-col  p-p-0 p-m-1" htmlFor="mail">E-Mail</label>
                                <InputText autoComplete="nope" value={email} id="mail" className={'p-col ' + (email === '' ? 'p-invalid' : '')} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="p-field" style={id ? { display: 'none' } : {}}>
                                <label className="p-col  p-p-0 p-m-1" htmlFor="password">Passwort</label>
                                <InputText autoComplete="nope" value={password} id="password" className={'p-col ' + (password === '' ? 'p-invalid' : '')} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="p-field" style={!id ? { display: 'none' } : {}}>
                                <label className="p-col  p-p-0 p-m-1" htmlFor="password">Passwort</label>
                                <Button label='Passwort setzen' id="button_password" className='p-col ' onClick={(e) => setDialogPassword(true)} />
                            </div>
                            <div className="p-field-checkbox p-mt-4" style={!id ? { display: 'none' } : {}}>
                                <Checkbox inputId="forceChangePasswordbinary" onChange={e => setForceChangePassword(e.checked)} checked={forceChangePassword} />
                                <label htmlFor="forceChangePassword">{'Benutzer muss Passwort ändern'}</label>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-6">
                        <div className="p-fluid ">
                            <div className="p-field">
                                <label className="p-col  p-p-0 p-m-1" >Rollen</label>
                                {rolesList.map((role) => {
                                    return (
                                        <div key={role.alias} className="p-field-checkbox">
                                            <Checkbox inputId={role.alias} name="role" value={role} onChange={onRoleChange} checked={roles.includes(role.alias)} />
                                            <label htmlFor={role.id}>{role.display}</label>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="p-field">
                                <label className="p-col  p-p-0 p-m-1" >Berechtigungen</label>
                                {permissionsList.map((permission) => {
                                    return (
                                        <div key={permission.alias} className="p-field-checkbox">
                                            <Checkbox inputId={permission.alias} name="role" value={permission} onChange={onPermissionChange} checked={permissions.includes(permission.alias)} />
                                            <label htmlFor={permission.id}>{permission.display}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}