import { reactLocalStorage } from 'reactjs-localstorage';
import winston, { createLogger, format, transports } from 'winston';


// Configure the Winston logger. For the complete documentation see https://github.com/winstonjs/winston

winston.addColors({
	verbose: 'magenta',
});

const loglevel = reactLocalStorage.get('loglevel', 'error');

const createFormat = (_label: any) => {
	return format.combine(
		format.colorize({ all: true }),
		format.timestamp({ format: 'YYYY-MM-DD HH:mm:ss' }),
		format.label({ label: _label }),
		format.splat(),
		format.printf((info: any) => `[${info.timestamp}] ${info.label}: ${info.message}`)
	)
}

const logger = createLogger({
	level: loglevel, format: createFormat(''), transports: [new transports.Console({ handleExceptions: true })]
});

export const LoggerContext = createLogger({
	level: loglevel,
	format: createFormat('[C]'),
	transports: [
		new transports.Console({
			format: format.combine(
				format.colorize(),
			)
		})]
});
export const LoggerLogin = createLogger({
	level: loglevel, format: createFormat('[L]'), transports: [new transports.Console({ handleExceptions: true })]
});

export default logger;
