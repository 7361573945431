import React, {useContext , useState, useEffect} from "react";
import { getData , updateListEntryInProvider, updateServiceListener} from '../feathers'
import {LoggerContext} from "../Utils/logger";

const UserContext = React.createContext();

const servicename = 'users';
const listenernames = ['created','patched','updated'];
const providername = 'UserProvider';

export function useUserList(){
    return useContext(UserContext);
}

export function UserProvider({children}){
    const [list, setList] = useState([]);
    const [listItemChanged,setListItemChanged] = useState();

    useEffect(()=>{
        if(listItemChanged){
            updateListEntryInProvider(providername,listItemChanged,list,setList,servicename,false)
            setListItemChanged(null);
        }
    },[listItemChanged,list])

    useEffect(()=>{
        LoggerContext.debug('########### ' + providername + ' useEffect update');
        updateServiceListener(servicename,listenernames,(_listentry) => setListItemChanged(_listentry));    
    },[list])

    useEffect(()=>{
        LoggerContext.debug('########### ' + providername + ' useEffect');
        getData(servicename).then((_list)=>{
            LoggerContext.info(`########### ${providername} list: ${JSON.stringify(_list)}`)
            setList(_list);
        }).catch((error) => {LoggerContext.error("Error " + providername,error)});
        return () => {updateServiceListener(servicename,listenernames,null); }
    },[])
    return (
        <UserContext.Provider value={list}>
                {children}
        </UserContext.Provider>
    )
}