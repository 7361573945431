import logger from '../Utils/logger';
import { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { format, isAfter, isBefore, parseISO } from 'date-fns'
import TimeKeeper from 'react-timekeeper';
import DatePicker from "react-datepicker";
import { patchData, getCaptureActivityForReport, getCaptureActivityTimerange } from '../feathers';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Tree } from 'primereact/tree';
import './OperationReport.scss'
import "react-datepicker/dist/react-datepicker.css";


export const OperationReportDialog = ({ selectedReport, setSelectedReport }) => {

    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [otherReportItems, setOtherReportItems] = useState([]);
    const [startTime, setStartTime] = useState('00:00')
    const [showStartTime, setShowStartTime] = useState(false)
    const [endTime, setEndTime] = useState('00:00')
    const [showEndTime, setShowEndTime] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [subject, setSubject] = useState('');
    const [comment, setComment] = useState('');
    const [scrollable, setScrollable] = useState();
    const [reportType, setReportType] = useState();
    const [employeeCaptureActivity,setEmployeeCaptureActivity] = useState();
    const [employeeCaptureActivityList,setEmployeeCaptureActivityList] = useState([]);


    useEffect(()=>{

        if(selectedReport){
            console.log("selectedReport:",selectedReport)
            setComment(selectedReport.comment);
            setSubject(selectedReport.subject);
            setReportType(selectedReport.type);
            setStartTime(format(selectedReport.start,'HH:mm'));
            setEndTime(format(selectedReport.end,'HH:mm'));
            setStartDate(selectedReport.start);
            setEndDate(selectedReport.end);

            const startDateTime = selectedReport.start;
            const endDateTime = selectedReport.end;

            logger.info(" -- startDateTime:" + format(startDateTime, 'yyyy-MM-dd HH:mm') + " endDateTime+" + format(endDateTime, 'yyyy-MM-dd HH:mm'));
            const existingItems = [];
            selectedReport.capture_activities.forEach((arrive) => {
                if(arrive.arriveId === arrive.id){
                    const leave = selectedReport.capture_activities.find((e) => (e.arriveId === arrive.id && e.id !== arrive.id))
                    const start = parseISO(arrive.timestamp);
                    const end = parseISO(leave.timestamp);
                    const { lastname, firstname } = arrive.employee; 
                    existingItems.push({
                        id: arrive.id, name: lastname + ', ' + firstname, isService: arrive.captureTypeId !== 2,
                        arrive,leave,
                        isStartDate: format(startDateTime, 'dd.MM HH:mm') === format(start, 'dd.MM HH:mm'),
                        isEndDate: format(endDateTime, 'dd.MM HH:mm') === format(end, 'dd.MM HH:mm'),
                        start: format(start, 'dd.MM HH:mm'), end: format(end, 'dd.MM HH:mm'), before: isBefore(start, startDateTime), after: isAfter(end, endDateTime),
                    })
                }
            })
            setSelectedItems(existingItems);

        }
    },[selectedReport])

    useEffect(() => {
        const startDateTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.slice(0, 2), startTime.slice(3, 5));
        const endDateTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.slice(0, 2), endTime.slice(3, 5));
        logger.info(" -- startDateTime:" + format(startDateTime, 'yyyy-MM-dd HH:mm') + " endDateTime+" + format(endDateTime, 'yyyy-MM-dd HH:mm'));
        getCaptureActivityTimerange([1, 2, 3], startDateTime, endDateTime).then((captureActivityData) => {
            setEmployeeCaptureActivityList(captureActivityData);

            const listItems = [];
            const otherReportItems = [];
            captureActivityData.map((employeeCaptureActivity) => {
                if(employeeCaptureActivity.employee){
                    const { lastname, firstname } = employeeCaptureActivity.employee;
                    employeeCaptureActivity.data.forEach((entry) => {
                        const { arrive, leave, duration } = entry;
                        const start = parseISO(arrive.timestamp);
                        const end = parseISO(leave.timestamp);
                        const item = {
                            id: arrive.id, name: lastname + ', ' + firstname, isService: arrive.captureTypeId !== 2,
                            employeeId: arrive.employeeId,
                            arrive,leave,duration,
                            isStartDate: format(startDateTime, 'dd.MM HH:mm') === format(start, 'dd.MM HH:mm'),
                            isEndDate: format(endDateTime, 'dd.MM HH:mm') === format(end, 'dd.MM HH:mm'),
                            start: format(start, 'dd.MM HH:mm'), end: format(end, 'dd.MM HH:mm'), before: isBefore(start, startDateTime), after: isAfter(end, endDateTime),
                        }
                        if(item.arrive.reportId !== null && item.arrive.reportId !== selectedReport.id){
                            otherReportItems.push(item);
                        }else{
                            listItems.push(item);
                        }
                    });
                }               
            });
            console.info(`otherReportItems + ${otherReportItems.length}`, otherReportItems);
            console.info(`listItems`, listItems);
            const srollheight = 100;
            const _scrollable = (srollheight / (listItems.length === 0 ? 1 : listItems.length)) < 32
            logger.info(`getCaptureActivityData ${listItems.length}\nfactor for scrollable (limit 32):${srollheight / (listItems.length === 0 ? 1 : listItems.length)} == ${_scrollable}`);
            setScrollable(_scrollable);
            setItems(listItems);
            setOtherReportItems(otherReportItems);
        })

    }, [startTime, endTime, startDate, endDate, setScrollable])

    const handleSaveReport = () => {
        const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.slice(0, 2), startTime.slice(3, 5));
        const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.slice(0, 2), endTime.slice(3, 5));
        const report = {
            id:selectedReport.id, start, end, subject, comment, type:reportType
        }
        console.debug("selectedItems:",selectedItems)
        patchData('report',report).then(async (_report)=>{
            console.debug("_report :" ,_report)
            getCaptureActivityForReport(_report.id).then(async (_listCaptureActivity)=>{
                console.debug("_listCaptureActivity 1:",_listCaptureActivity)
                await selectedItems.forEach((_entry)=>{
                    const { arrive, leave } = _entry;
                    arrive.reportId = _report.id
                    arrive.arriveId = arrive.id
                    leave.reportId = _report.id
                    leave.arriveId = arrive.id
                    patchData('capture-activity',arrive);          
                    patchData('capture-activity',leave);           
                    _listCaptureActivity = _listCaptureActivity.filter(item => (item.id !== arrive.id && item.id !== leave.id));
                });
                await _listCaptureActivity.forEach((_captureActivity)=>{
                    _captureActivity.reportId = null;
                    patchData('capture-activity',_captureActivity);
                });
                patchData('report',_report);
            })          
        });
       
        setSelectedReport(null);
    }

    const beforeBodyTemplate = (rowData) => {
        return (
            <div className="p-grid operationreport_column ">
                <div className="p-col operationreport_column_time p-d-flex p-jc-end" >{!rowData.before && !rowData.isStartDate ? '' : rowData.start}</div>
                {rowData.before ? <div className="p-col-fixed operationreport_column_bar" /> : ''}
            </div>
        );
    }
    const afterBodyTemplate = (rowData) => {
        return (
            <div className="p-grid operationreport_column" >
                {rowData.after && !rowData.isEndDate ? <div className="p-col-fixed operationreport_column_bar" /> : ''}
                <div className="p-col operationreport_column_time"  >{!rowData.after ? '' : rowData.end}</div>
            </div>
        );
    }
    const bodyTemplate = (rowData) => {

        return (
            <div className="p-grid operationreport_column">
                {!rowData.before && !rowData.isStartDate ? <div className="p-col-fixed p-d-flex p-jc-end operationreport_column_time">{rowData.start}</div> : ''}
                <div className="p-col p-d-flex p-jc-center operationreport_column_bar">
                    {(rowData.isService ? 'Dienst ' : '') + rowData.duration}
                </div>
                {!rowData.after && !rowData.isEndDate ? <div className="p-col-fixed operationreport_column_time">{rowData.end}</div> : ''}
                {rowData.before && rowData.after ? <div className="p-col-fixed operationreport_column_placeholder">{'----'}</div> : ''}
            </div>
        );
    }

    const renderHeader = () => {
        return (
            <div className="p-fluid p-formgrid p-grid" style={{ width: '100%' }}>
                <div className="p-col-2">
                    <label htmlFor="reporttype" className="p-col" >Titel</label>
                    <Dropdown placeholder="Reportart" optionLabel="name" optionValue="type" options={[{name:'Alarm',type:1},{name:'Übung',type:2},{name:'Dienst',type:3}]} value={reportType} onChange={(e) => setReportType(e.value)} />
                </div>
                <div className="p-col-3">
                    <label htmlFor="subject" className="p-col-fixed" style={{ width: '180px' }}>Thema / Titel</label>
                    <InputText id="subject" type="text" placeholder="Thema" value={subject} onChange={(e) => setSubject(e.target.value)} />
                </div>
                <div className="p-col-5">
                    <label htmlFor="comment" className="p-col-fixed" style={{ width: '180px' }}>Kommentar</label>
                    <InputText id="comment" type="text" placeholder="Kommentar" value={comment} onChange={(e) => setComment(e.target.value)}/>
                </div>
                <div className="p-col-2 p-d-flex p-as-end">
                    <Button icon="pi pi-check" label={'Speichern'} onClick={handleSaveReport}></Button>
                </div>
            </div>
        )
    }

    const renderStartTime = () => {
        return <div className='operationreportdialog_datepicker'>
            <div className='p-d-flex p-jc-center' >Start</div>
            <Button  disabled={selectedReport && selectedReport.id !== null} icon="pi pi-calendar" onClick={() => setShowStartTime(true)}> <div>{`${format(startDate, 'dd.MM.yyyy')} | ${startTime}`}</div></Button>
            <Dialog header={<DatePicker dateFormat='dd.MM.yyyy' selected={startDate} onChange={(date) => setStartDate(date)} />} style={{ width: '300px' }} closable={false} showHeader={true} visible={showStartTime} onHide={() => setShowStartTime(false)}>
                <TimeKeeper
                    time={startTime}
                    hour24Mode switchToMinuteOnHourSelect
                    onChange={(newTime) => setStartTime((newTime.formatted24.length === 4 ? '0' : '') + newTime.formatted24)}
                    onDoneClick={() => setShowStartTime(false)}
                    doneButton={(newTime) => (<div className='operationreportdialog_datepicker_donebutton' onClick={() => setShowStartTime(false)}>OK</div>)}
                />
            </Dialog>
        </div>
    }

    const renderEndTime = () => {
        return <div className='operationreportdialog_datepicker' >
            <div className='p-d-flex p-jc-center'>Ende</div>
            <Button disabled={selectedReport && selectedReport.id !== null} icon="pi pi-calendar" onClick={() => setShowEndTime(true)}> <div>{`${format(endDate, 'dd.MM.yyyy')} | ${endTime}`}</div></Button>
            <Dialog header={<DatePicker dateFormat='dd.MM.yyyy' selected={endDate} onChange={(date) => setEndDate(date)} />} style={{ width: '300px' }} closable={false} showHeader={true} visible={showEndTime} onHide={() => setShowEndTime(false)}>
                <TimeKeeper
                    time={endTime}
                    hour24Mode switchToMinuteOnHourSelect
                    onChange={(newTime) => setEndTime((newTime.formatted24.length === 4 ? '0' : '') + newTime.formatted24)}
                    onDoneClick={() => setShowEndTime(false)}
                    doneButton={(newTime) => (<div className='operationreportdialog_datepicker_donebutton' onClick={() => setShowEndTime(false)}>OK</div>)}
                />
            </Dialog>
        </div>
    }

    const nodeTemplate = (node, options) => {
        let label = <b>{node.label}</b>;

        return (
            <span >
                {label}
            </span>
        )
    }
    return (
        <Dialog id='operationreportdialog' closable={true} showHeader={true} header={renderHeader()} visible={selectedReport !== null} onHide={() => setSelectedReport(null)}>
 
            <Dialog id='operationreportdialog_captureActivityList'
                header={employeeCaptureActivity?employeeCaptureActivity.key + ': ' +  employeeCaptureActivity.employee.lastname + ', ' + employeeCaptureActivity.employee.firstname:''} 
                style={{ width: '400px' }} closable={true} showHeader={true} visible={employeeCaptureActivity} 
                onHide={() => setEmployeeCaptureActivity(null)}>
                <Tree value={employeeCaptureActivity?employeeCaptureActivity.list:[]} nodeTemplate={nodeTemplate} />
            </Dialog>

            <div id='operationreportdialog_panel' className='p-shadow-12'>

                <DataTable 
                    className='operationreport'
                    scrollable={scrollable} scrollHeight="400px"
                    value={items}
                    selection={selectedItems}
                    onRowDoubleClick={(e)=> {const captureActivityEmployee = employeeCaptureActivityList.find((entry) => entry.id === e.data.employeeId);console.info("onRowClick",captureActivityEmployee);setEmployeeCaptureActivity(captureActivityEmployee); }}
                    onSelectionChange={(e) => setSelectedItems(e.value)}
                    dataKey="id">
                    <Column field="name" headerClassName='operationreport_column_name' className='operationreport_column_name'></Column>
                    <Column selectionMode="multiple" headerClassName='operationreport_column_selection'></Column>
                    <Column headerClassName='operationreport_column_before' body={beforeBodyTemplate} />
                    <Column header={renderStartTime()} headerStyle={{ borderWidth: '0 0 0 0', width: '1px', padding: '0px' }} style={{ borderWidth: '0 2px 0 0', borderColor: 'red' }} />
                    <Column headerClassName='operationreport_column_middle' body={bodyTemplate} />
                    <Column header={renderEndTime()} headerStyle={{ borderWidth: '0 0 0 0', width: '1px', padding: '0px' }} style={{ borderWidth: '0 2px 0 0', borderColor: 'red' }} />
                    <Column headerClassName='operationreport_column_after' body={afterBodyTemplate} />
                    <Column header="" ></Column>
                </DataTable>

                <DataTable style={otherReportItems.length === 0?{display:'none'}:{}}
                    className='operationreport_othereports'
                    scrollable={scrollable} scrollHeight="400px"
                    value={otherReportItems}
                    dataKey="id">
                    <Column field="name" headerClassName='operationreport_column_name' className='operationreport_column_name'></Column>
                    <Column selectionMode="multiple" headerClassName='operationreport_column_selection'></Column>
                    <Column headerClassName='operationreport_column_before' body={beforeBodyTemplate} />
                    <Column headerStyle={{ borderWidth: '0 0 0 0', width: '1px', padding: '0px' }} style={{ borderWidth: '0 2px 0 0', borderColor: 'red' }} />
                    <Column headerClassName='operationreport_column_middle' body={bodyTemplate} />
                    <Column headerStyle={{ borderWidth: '0 0 0 0', width: '1px', padding: '0px' }} style={{ borderWidth: '0 2px 0 0', borderColor: 'red' }} />
                    <Column headerClassName='operationreport_column_after' body={afterBodyTemplate} />
                    <Column header="" ></Column>
                </DataTable>
            </div>
        </Dialog>
    )
}