import React, {useContext , useState, useEffect} from "react";
import { getData } from '../feathers'
import { LoggerContext } from "../Utils/logger";

const providername = 'RoleProvider';

const RoleContext = React.createContext();

export function useRoleList(){
    return useContext(RoleContext);
}

export function RoleProvider({children}){
    const [roleList, setRoleList] = useState([]);

    useEffect(()=>{
        LoggerContext.debug('########### RoleProvider useEffect');
        getData('roles').then((_list)=>{
            LoggerContext.info(`########### ${providername} list: ${JSON.stringify(_list)}`)
            setRoleList(_list);
        }).catch((error) => {LoggerContext.error("Error " + providername,error)});
    },[])

    return (
        <RoleContext.Provider value={roleList}>
            {children}
        </RoleContext.Provider>
    )
}