import { useState, useEffect, useCallback } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { confirmPopup } from 'primereact/confirmpopup';
import { format, parseISO } from 'date-fns';
import { useSite } from '../Persistence/SiteContext';
import { Dialog } from 'primereact/dialog';
import { useAvailabilityMatrix } from '../Persistence/AvailabilityContext';
import { useEmployee } from '../Persistence/EmployeeContext';

const getDataTable = (duration, objectSites) => {
	const hours = [];
	for (var hour = 0; hour < 24; hour++) {
		const days = {};
		for (var i = 0; i < duration; i++) {
			days["day_" + i] = { ...objectSites }
		}
		hours.push(days);
	}
	return hours;
}

const bodyDataCell = (isWeekend, dayId, hour, siteID_AA, handleClickSlot) => {

	const availabititySites = hour.sites;
	const aa = availabititySites.find(e => e.alias === 'AA');
	const onw = availabititySites.find(e => e.alias === 'ONW');
	const osw = availabititySites.find(e => e.alias === 'OSW');

	if (!aa || aa.listNotAvailableEmployees === null) {
		return '';
	}

	let oko_classname = "dataCellOK";
	let aa_classname = "dataCellOK";

	let listRadius = ['radius_1', 'radius_2', 'radius_3']

	listRadius.forEach((radius) => {


		if (!onw.functionMatrix[radius].required.checkFunctions || !onw.functionMatrix[radius].required.checkPersons || !osw.functionMatrix[radius].required.checkFunctions || !osw.functionMatrix[radius].required.checkPersons) {
			oko_classname = "dataCellAlarm";
		} else if (!onw.functionMatrix[radius].optional.checkFunctions || !onw.functionMatrix[radius].optional.checkPersons || !osw.functionMatrix[radius].optional.checkFunctions || !osw.functionMatrix[radius].optional.checkPersons) {
			if (oko_classname === 'dataCellOK') {
				oko_classname = "dataCellWarning";
			}
		}
		if (!aa.functionMatrix[radius].required.checkFunctions || !aa.functionMatrix[radius].required.checkPersons) {
			aa_classname = "dataCellAlarm";
		} else if (!aa.functionMatrix[radius].optional.checkFunctions || !aa.functionMatrix[radius].optional.checkPersons) {
			if (aa_classname === 'dataCellOK') {
				aa_classname = "dataCellWarning";
			}
		}
	})


	const aa_available = aa.activeEmployee - aa.listNotAvailableEmployees.length;
	const oko_available = onw.activeEmployee + osw.activeEmployee - onw.listNotAvailableEmployees.length - osw.listNotAvailableEmployees.length;

	return (<div className={'p-d-flex ' + (isWeekend ? ' weekend' : '')} >
		<div className={'p-d-flex p-jc-center ' + oko_classname} onClick={() => handleClickSlot(dayId, hour.id, [onw, osw])}>
			{oko_available}
		</div>
		<div className='dataCellDelimiter' />
		<div className={'p-d-flex p-jc-center ' + aa_classname} onClick={() => handleClickSlot(dayId, hour.id, [aa])}>
			{aa_available}
		</div>
	</div>
	);
}

const bodyTitleCell = (hour) => {
	return (<div className='p-d-flex p-jc-center dataCellHour'>
		{hour + ' - ' + (hour + 1) + ' Uhr'}
	</div>
	);
}

export const AvailabilityPreview = () => {

	const [dialogMatrixVisibility, setDialogMatrixVisibility] = useState(false);
	const [dialogMatrixData, setDialogMatrixData] = useState(false);
	const [dialogMatrixTitle, setDialogMatrixTitle] = useState("");
	const [availability, setAvailability] = useState(getDataTable());
	const [dataTableColumns, setDataTableColumns] = useState([]);
	const matrixData = useAvailabilityMatrix();
	const listSites = useSite();
	const employees = useEmployee();

	useEffect(() => {
		if (listSites && matrixData && employees) {
			console.log("matrixData: ", matrixData);
			var siteID_AA = 'AA';
			var objectSites = { total: 0 }

			const dataTable = getDataTable(14, objectSites);
			const titles = [{ id: -1, title: 'Uhrzeit', field: 'hour', className: 'dataHeaderHour' }];
			matrixData.forEach((day) => {
				titles.push({ id: day.id, title: format(parseISO(day.date), 'dd.MM.'), field: 'day_' + day.id, className: 'dataHeaderDate' + (day.weekday === 0 || day.weekday === 6 ? ' weekend' : '') })
				day.hours.forEach((hour) => {
					dataTable[hour.id].hour = bodyTitleCell(hour.id);
					dataTable[hour.id]['day_' + day.id] = bodyDataCell((day.weekday === 0 || day.weekday === 6), day.id, hour, siteID_AA, handleClickSlot)
				})
			});
			setDataTableColumns(titles);
			setAvailability(dataTable);
			//console.log("Availabilty 2: " + siteID_AA, dataTable);
		}
	}, [listSites, matrixData, employees]);

	const handleClickSlot = async (dayId, hourId, sites) => {
		const hour = matrixData[dayId].hours[hourId];
		const dateStart = parseISO(hour.dateStart);
		const dateEnd = parseISO(hour.dateEnd);
		const title = (sites.length === 1 ? 'AA' : 'OKO') + " " + format(dateStart, 'dd.MM') + ' | ' + format(dateStart, 'HH') + '-' + format(dateEnd, 'HH') + ' Uhr'
		setDialogMatrixData(generateDataTableDialog(sites, hour));
		setDialogMatrixTitle(title);
		setDialogMatrixVisibility(true);
	}

	const geneateDataTable = () => {
		return (dataTableColumns.map((col) => {
			return <Column key={col} field={col.field} style={col.title === 'Uhrzeit' ? { width: '80px' } : {}} header={col.title} headerClassName={col.className} className='operatorView_content_corona' />;
		}));
	}

	const generateTableHeader = (title, matrix, listQualifications, listPossible) => {
		return <tr key='tr-head'>
			<th key={'th-title'}>{title}</th>
			<th onClick={getHandleOnClick({ title: 'Alle Personen', listQualifications, listPossible })} className='table-header-person-list' style={{ width: '40px' }} >{<i className="pi pi-user" style={{ 'fontSize': '1em' }}></i>}</th>{
				Object.getOwnPropertyNames(matrix.definedFunctions).map((entry) => {
					return <th key={'th-' + entry} style={{ width: '40px' }} >{entry}</th>
				})}</tr>
	}

	const getHandleOnClick = useCallback((parameter) => {
		const { title, checkedFunctionsGreen, checkedFunctionsYellow, listQualifications, listPossible } = parameter;
		let footer = null;
		let countPersonsWithQuals = 0;

		if (listPossible) {
			let tableHeader = [<th key={'th-name'} style={{ textAlign: 'center' }}>Name</th >];
			listQualifications.forEach(e => tableHeader.push(<th key={'th-' + e.id} style={{ width: '40px', textAlign: 'center' }}>{e}</th>))
			const tableRows = [<tr key={'tr-header'}>{tableHeader}</tr>]

			listPossible.forEach((e) => {
				const employee = employees.find(employee => employee.id === e.id);
				e.name = employee ? employee.lastname + ', ' + employee.firstname + ' (' + employee.site.alias + ')' : 'n.n.'
			})
			listPossible.sort((a, b) => { return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 0 })
			listPossible.forEach((person) => {
				if (person.qualifications.length > 0) {
					countPersonsWithQuals++;
					const row = [<td key={'td-' + person.id} style={{ textAlign: 'left' }}>{person.name}</td>];
					listQualifications.forEach(q => {
						const checkedYellow = checkedFunctionsYellow && checkedFunctionsYellow[q] && (checkedFunctionsYellow[q].find(entry => entry.id === person.id));
						const checkedGreen = checkedFunctionsGreen && checkedFunctionsGreen[q] && (checkedFunctionsGreen[q].find(entry => entry.id === person.id));
						const style = checkedYellow ? { backgroundColor: 'lightyellow' } : checkedGreen ? { backgroundColor: 'lightgreen' } : {};
						row.push(person.qualifications.includes(q) ? (checkedYellow || checkedGreen ? <td key={'td-' + q.id} style={style}>x</td> : <td key={'td-' + q.id}>x</td>) : <td key={'td-' + q.id}></td>)
					});
					tableRows.push(<tr key={'tr-' + person.id}>{row}</tr>)
				}
			})

			footer = <div style={{ maxHeight: '600px', overflow: 'auto' }}><table className='dialogTableMatrix' style={{ textAlign: 'left', width: '400px', marginBottom: '2px' }}><tbody>{tableRows}</tbody></table></div>
		}

		if (footer !== null) {
			return (event) => {
				confirmPopup({ target: event.currentTarget, icon: 'pi pi-user', message: title + ' ' + countPersonsWithQuals, footer, });
			}
		} else {
			return () => { }
		}
	}, [employees])

	const generateTableRow = (rowtitle, listQualifications, total, matrix, checkMatrix, listPossible, checkedFunctionsYellow, checkedFunctionsGreen, classnameForLessCount) => {
		return <tr onClick={getHandleOnClick({ title: 'Mögliche Personen', checkedFunctionsYellow, checkedFunctionsGreen, listQualifications, listPossible })}>
			<td key={'td-head'} className={(listPossible ? 'table-header-person-list ' : '')}>{rowtitle}</td><td style={{ width: '40px' }} className={(listPossible ? 'table-header-person-list ' : '')}>{total}</td>
			{Object.getOwnPropertyNames(matrix).map((entry) => {
				const count = matrix[entry].length ? matrix[entry].length : matrix[entry];
				const countCheck = checkMatrix[entry] ? checkMatrix[entry] : 0;
				return <td key={'td-' + entry} style={{ width: '40px' }} className={(listPossible ? 'table-header-person-list ' : '') + ((count - countCheck < 0) ? classnameForLessCount : '')}>{count}</td>
			})}</tr>
	}

	const generateDataTableSite = (site, hour) => {
		const listQualifications = site.functionMatrix.qualifications.sort();
		const listPossible = [];

		employees.forEach((employee) => {
			if (employee.siteId === site.id) {
				let quals = employee.qualification_functions.map(q => { return listQualifications.includes(q.alias) ? q.alias : '' });
				quals = quals.filter(item => item)
				if (quals.length > 0) {
					listPossible.push({ id: employee.id, qualifications: quals });
				}
			}
		})
		const possible1 = site.functionMatrix.radius_1.possible;
		const possible2 = site.functionMatrix.radius_2.possible;
		const possible3 = site.functionMatrix.radius_3.possible;
		const required1 = site.functionMatrix.radius_1.required;
		const required2 = site.functionMatrix.radius_2.required;
		const required3 = site.functionMatrix.radius_3.required;
		const optional1 = site.functionMatrix.radius_1.optional;
		const optional2 = site.functionMatrix.radius_2.optional;
		const optional3 = site.functionMatrix.radius_3.optional;
		const header1 = generateTableHeader('Erstausrückende', required1, listQualifications, listPossible)
		const header3 = generateTableHeader('zusätzliche Kräfte', required3, listQualifications, listPossible)
		const headerOptional1 = generateTableHeader('Erstausrückende', optional1)
		const headerOptional3 = generateTableHeader('zusätzliche Kräfte', optional3)
		return <div key={'site-' + site.id}>
			<div className='dialogTableMatrixHeadline'>Standort {site.alias}</div>
			<table className='dialogTableMatrix' style={{ width: '100%', marginBottom: '2px' }} >
				<tbody>{header1}
					{generateTableRow('definiert gelb', listQualifications, required1.minCountPerson, required1.definedFunctions, required1.definedFunctions)}
					{generateTableRow('tatsächlich', listQualifications, possible1.length, required1.checkedFunctions, required1.definedFunctions, possible1, required1.checkedFunctions, optional1.checkedFunctions, 'qualificationFunction_red')}
				</tbody>
			</table>
			<table className='dialogTableMatrix' style={{ width: '100%', marginBottom: '5px' }} >
				<tbody>
					{generateTableRow('definiert grün', listQualifications, optional1.minCountPerson, optional1.definedFunctions, optional1.definedFunctions)}
					{generateTableRow('tatsächlich', listQualifications, possible1.length, optional1.checkedFunctions, optional1.definedFunctions, possible1, required1.checkedFunctions, optional1.checkedFunctions, 'qualificationFunction_yellow')}
				</tbody>
			</table>
			<table className='dialogTableMatrix' style={{ width: '100%', marginBottom: '2px' }} >
				<tbody>{header3}
					{generateTableRow('definiert gelb', listQualifications, required3.minCountPerson, required3.definedFunctions, required3.definedFunctions)}
					{generateTableRow('tatsächlich', listQualifications, (possible2.length + possible3.length), required3.checkedFunctions, required3.definedFunctions, possible2.concat(possible3), required3.checkedFunctions, 'qualificationFunction_red')}
				</tbody>
			</table>
			<table className='dialogTableMatrix' style={{ width: '100%', marginBottom: '5px' }} >
				<tbody>
					{generateTableRow('definiert grün', listQualifications, optional3.minCountPerson, optional3.definedFunctions, optional3.definedFunctions)}
					{generateTableRow('tatsächlich', listQualifications, (possible2.length + possible3.length), optional3.checkedFunctions, optional3.definedFunctions, possible2.concat(possible3), optional3.checkedFunctions, 'qualificationFunction_yellow')}
				</tbody>
			</table>
		</div>
	}

	const generateDataTableDialog = (sites, hour) => {

		return <div key={'sites'}>{sites.map((site) => {
			return generateDataTableSite(site, hour);
		})} </div>

	}

	return (
		<div className="availablePreview">
			<Dialog id='dialogAvailablePreview' header={dialogMatrixTitle} visible={dialogMatrixVisibility} style={{ width: '360px' }} onHide={() => setDialogMatrixVisibility(false)}>
				{dialogMatrixData}
			</Dialog>
			<DataTable value={availability} className="p-datatable-header p-datatable-sm">
				{geneateDataTable()}
			</DataTable>
		</div>
	)
}