import React from 'react';
import ReactDOM from 'react-dom';
import { Application } from './Application';
import reportWebVitals from './reportWebVitals';
import { CurrentUserProvider } from './Persistence/CurrentUserContext';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
      <CurrentUserProvider><Application /></CurrentUserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();
